<template>
  <div>
    <v-row>
      <v-col>
        <v-alert color="cyan" border="left" elevation="2" colored-border>
          <v-row align="center">
            <v-col class="grow">
              {{
                selected_ad_account === null
                  ? "Please select an ad account first to continue!"
                  : "🎉 Creating campaign under " + selected_ad_account.name
              }}
            </v-col>
            <v-col>
              <v-select
                outlined
                dense
                label="Choose Ad Account"
                class="shrink"
                item-text="name"
                return-object
                append-icon="mdi-chevron-down"
                :items="ad_accounts"
                v-model="selected_ad_account"
                v-if="this.$route.name == 'create-facebook-campaign'"
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-select>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <!-- <div class="d-flex justify-space-between my-8">
      <h4 class="mr-4">Create Facebook Campaign</h4>
      <v-select
        outlined
        dense
        label="Choose Ad Account"
        class="shrink"
        item-text="name"
        return-object
        append-icon="mdi-chevron-down"
        :items="ad_accounts"
        v-model="selected_ad_account"
        v-if="this.$route.name == 'create-facebook-campaign'"
        @input="emitAdAccount = setAdAccount($event)"
        hide-details
        :menu-props="{ bottom: true, offsetY: true }"
      ></v-select>
    </div> -->
    <v-tabs
      slot="extension"
      slider-color="primary"
      background-color="#F5F7FB"
      v-model="tab"
    >
      <new-campaign-topbar />
      <v-tabs-items :value="tab">
        <v-tab-item value="general">
          <new-campaign
            :clients="clients"
            @create="createCampaign($event)"
            @update="updateCampaign($event)"
            :facebook_client_pages="facebook_client_pages"
            :facebook_campaign="facebook_campaign"
          />
        </v-tab-item>
        <v-tab-item value="adset">
          <ad-set
            @create="createAdset($event)"
            @update="updateAdset($event)"
            @emitZip="postZipInt($event)"
            @emitWork="postWork($event)"
            @emitCity="postCity($event)"
            @emitRegions="postRegions($event)"
            @emitSelectedLanguage="postLanguage($event)"
            @emitSpecificLocation="postSpecificLocation($event)"
            @emitMajor="postEducationMajor($event)"
            @emitJobTitles="postJobTitles($event)"
            @fetchCampaigns="refetchCampaigns($event)"
            :custom_audiences="custom_audiences"
            :all_countries="all_countries"
            :all_regions="all_regions"
            :all_cities="all_cities"
            :all_incomes="all_incomes"
            :all_zips="all_zips"
            :all_employers="all_employers"
            :all_majors="all_majors"
            :all_job_titles="all_job_titles"
            :available_interests="available_interests"
            :available_behaviors="available_behaviors"
            :all_languages="all_languages"
            :all_life_events="all_life_events"
            :all_industries="all_industries"
            :all_user_os="all_user_os"
            :available_demographics="available_demographics"
            :facebook_campaigns="facebook_campaigns"
            :facebook_single_adset="facebook_single_adset"
            :latest_campaign="latest_campaign"
          />
        </v-tab-item>
        <v-tab-item value="visuals">
          <ads
            :facebook_client_pages="facebook_client_pages"
            :ad_images="ad_images"
            :facebook_adsets="facebook_adsets"
            :facebook_ad_creative="facebook_ad_creative"
            :facebook_post_preview="facebook_post_preview"
            :facebook_campaigns="facebook_campaigns"
            :latest_campaign="latest_campaign"
            :ad_video="ad_video"
            @uploadVideo="uploadVideo($event)"
            @postAdCreative="postImageCreative($event)"
            @uploadImage="uploadImage($event)"
            @postAd="postAd($event)"
            @emitPreviewOption="postPreviewOption($event)"
            @emitSelectedCampaign="fetchAdsets($event)"
            @createCarousel="postCarouselAd($event)"
            @carouselAd="createCarouselAd($event)"
            @postCarouselCreatives="postCarouselCreatives($event)"
            @emitCarouselHeadline="emitCarouselHeadline($event)"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import NewCampaignTopbar from "../../../components/Layouts/Topbars/NewCampaignTopbar.vue";
import AdSet from "../../../components/Facebook/CreateCampaign/AdSet.vue";
import NewCampaign from "../../../components/Facebook/CreateCampaign/NewCampaign.vue";
import Ads from "../../../components/Facebook/CreateCampaign/Ads.vue";

import { facebookMixin } from "../../../mixins/facebookMixin";

export default {
  mixins: [facebookMixin],
  data: () => ({
    selected_ad_account: null,
    ad_headline: "",
    param_id: "",
    currentItem: "general",
    adAccountId: "",
    countries_params: {
      location_types: ["country"],
      type: "adgeolocation",
      q: "",
      limit: "1000",
    },
    regions_params: {
      location_types: ["region"],
      type: "adgeolocation",
    },
    language_params: {
      type: "adlocale",
      q: "",
    },
    city_params: {
      location_types: "city",
      type: "adgeolocation",
    },
    zips_params: {
      location_types: "zip",
      type: "adgeolocation",
    },
    interestsBody: {
      class: "interests",
      type: "adTargetingCategory",
    },
    behaviorsBody: {
      class: "behaviors",
      type: "adTargetingCategory",
    },
    work_params: {
      type: "adworkemployer",
    },
    majors_params: {
      type: "adeducationmajor",
    },
    life_events_params: {
      type: "adTargetingCategory",
      class: "life_events",
    },
    industry_params: {
      type: "adTargetingCategory",
      class: "industries",
    },
    income_params: {
      type: "adTargetingCategory",
      class: "income",
    },
    job_titles_params: {
      type: "adworkposition",
    },
    user_os_params: {
      type: "adTargetingCategory",
      class: "user_os",
    },
    error: null,
    campaignId: "",
  }),
  components: {
    NewCampaign,
    AdSet,
    Ads,
    NewCampaignTopbar,
  },
  async mounted() {
    //check if local storage has ad account
    if (localStorage.getItem("ad_account")) {
      // this.adAccountId = JSON.parse(localStorage.getItem("ad_account"));
      this.selected_ad_account = JSON.parse(localStorage.getItem("ad_account"));
    }

    this.param_id = this.$route.params.id;
    this.campaignId = this.$route.params.id;
    // this.adAccountId = sessionStorage.getItem("adAccountId");

    if (this.tab == "adset" && this.$route.name === "update-facebook-adset") {
      try {
        const ids = {
          adAccountId: this.adAccountId,
          adsetId: this.param_id,
        };
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("facebook/getFacebookAdset", ids);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error
        this.$store.commit("makingApiRequest", false);
      }
    }

    //Languages
    try {
      await this.$store.dispatch(
        "facebook/getAllLanguages",
        this.language_params
      );
    } catch (error) {
      this.$notify({
        title: error,
        group: "errors",
      });
    }

    //Countries
    try {
      await this.$store.dispatch(
        "facebook/getAllLocations",
        this.countries_params
      );
    } catch (error) {
      this.$notify({
        title: error,
        group: "errors",
      });
    }

    //Interests
    try {
      await this.$store.dispatch(
        "facebook/getaudienceInterest",
        this.interestsBody
      );
    } catch (error) {
      this.$notify({
        title: error,
        group: "errors",
      });
    }

    // getAllUserOs
    try {
      await this.$store.dispatch("facebook/getAllUserOs", this.user_os_params);
    } catch (error) {
      this.$notify({
        title: error,
        group: "errors",
      });
    }

    //Behaviours
    try {
      await this.$store.dispatch(
        "facebook/getaudienceBehaviors",
        this.behaviorsBody
      );
    } catch (error) {
      this.$notify({
        title: error,
        group: "errors",
      });
    }

    //Demographics
    // try {
    //   this.$store.commit("makingApiRequest", true);
    //   await this.$store.dispatch("getAllDemographics", this.demo_params);
    //   this.$store.commit("makingApiRequest", false);
    // } catch (error) {
    //   this.error = error;
    //   this.$store.commit("makingApiRequest", false);
    // }

    //Income
    try {
      await this.$store.dispatch("facebook/getAllIncome", this.income_params);
    } catch (error) {
      this.$notify({
        title: error,
        group: "errors",
      });
    }
    //Regions
    try {
      await this.$store.dispatch("facebook/getAllRegions", this.regions_params);
    } catch (error) {
      this.$notify({
        title: error,
        group: "errors",
      });
    }

    //Cities
    try {
      await this.$store.dispatch("facebook/getAllCities", this.city_params);
    } catch (error) {
      this.$notify({
        title: error,
        group: "errors",
      });
    }

    // Get Generic Campaign Details
    // try {
    //   this.$store.commit("makingApiRequest", true);
    //   await this.$store.dispatch("getOneFacebookCampaign", {
    //     adAccountId: await this.facebook_campaign.account_id,
    //     campaignId: this.campaignId
    //   });
    //   this.$store.commit("makingApiRequest", false);
    // } catch (error) {
    //   this.error = error;
    //   this.$store.commit("makingApiRequest", false);
    // }

    try {
      await this.$store.dispatch("facebook/getClientPages");
    } catch (error) {
      this.$notify({
        title: error,
        group: "errors",
      });
    }

    // try {
    //   await this.$store.dispatch("facebook/getAdImages", this.adAccountId);
    // } catch (error) {
    //   this.$notify({
    //     title: error,
    //     group: "errors",
    //   });
    // }

    // try {
    //   this.$store.commit("makingApiRequest", true);
    //   this.$store.dispatch("getFacebookAdsetsByCampaign", {
    //     adAccountId: this.adAccountId,
    //     campaignId: this.campaignId,
    //   });
    //   this.$store.commit("makingApiRequest", false);
    // } catch (error) {
    //   this.error = error;
    // }

    try {
      await this.$store.dispatch(
        "facebook/getAllLifeEvents",
        this.life_events_params
      );
    } catch (error) {
      this.$notify({
        title: error,
        group: "errors",
      });
    }

    try {
      await this.$store.dispatch(
        "facebook/getAllIndustries",
        this.industry_params
      );
    } catch (error) {
      this.$notify({
        title: error,
        group: "errors",
      });
    }
  },
  watch: {
    async adAccountId() {
      try {
        await this.$store.dispatch(
          "facebook/getCustomAudiences",
          this.adAccountId
        );
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
      }
    },
    async special_error() {
      // this.error = await this.special_error;
      this.$notify({
        title: await this.special_error,
        group: "errors",
      });
    },
    // latest_ad_account() {
    //   this.selected_ad_account = this.latest_ad_account;
    // },
    async selected_ad_account() {
      localStorage.setItem(
        "ad_account",
        JSON.stringify(this.selected_ad_account)
      );

      const ad_account_id = this.selected_ad_account.id.includes("act_")
        ? this.selected_ad_account.id.substring(4)
        : this.selected_ad_account.id;

      this.adAccountId = ad_account_id;

      await this.fetchCampaigns(ad_account_id);
      await this.fetchAdImages(ad_account_id);
    },
  },
  created() {
    this.readClients();
  },
  computed: {
    clients() {
      return this.$store.state.clients;
    },
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
    facebook_client_pages() {
      return this.$store.state.facebook.facebook_client_pages;
    },
    custom_audiences() {
      return this.$store.state.facebook.custom_audiences;
    },
    ad_images() {
      return this.$store.state.facebook.ad_images;
    },
    all_countries() {
      return this.$store.state.facebook.all_countries;
    },
    all_regions() {
      return this.$store.state.facebook.all_regions;
    },
    all_cities() {
      return this.$store.state.facebook.all_cities;
    },
    available_interests() {
      return this.$store.state.facebook.available_interests;
    },
    available_behaviors() {
      return this.$store.state.facebook.available_behaviors;
    },
    available_demographics() {
      return this.$store.state.facebook.available_demographics;
    },
    all_zips() {
      return this.$store.state.facebook.zip_codes;
    },
    all_languages() {
      return this.$store.state.facebook.all_languages;
    },
    all_life_events() {
      return this.$store.state.facebook.life_events;
    },
    all_industries() {
      return this.$store.state.facebook.industries;
    },
    all_incomes() {
      return this.$store.state.facebook.income;
    },
    all_majors() {
      return this.$store.state.facebook.all_majors;
    },
    all_employers() {
      return this.$store.state.facebook.employers;
    },
    all_job_titles() {
      return this.$store.state.facebook.job_titles;
    },
    all_user_os() {
      return this.$store.state.facebook.user_os;
    },
    facebook_adsets() {
      return this.$store.state.facebook.facebook_adsets_by_campaign;
    },
    facebook_ad_creative() {
      return this.$store.state.facebook.facebook_ad_creative;
    },
    facebook_post_preview() {
      return this.$store.state.facebook.facebook_post_preview;
    },
    facebook_ad() {
      return this.$store.state.facebook.facebook_ad;
    },
    ad_video() {
      return this.$store.state.facebook.ad_video;
    },
    facebook_single_adset() {
      return this.$store.state.facebook.facebook_single_adset;
    },
    facebook_campaign() {
      return this.$store.state.facebook.facebook_campaign;
    },
    special_error() {
      return this.$store.state.facebook.special_error;
    },
  },

  methods: {
    refetchCampaigns() {
      console.log(this.adAccountId, "retch");
    },
    async readClients() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("readClients");
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error
        this.$store.commit("makingApiRequest", false);
      }
    },
    emitCarouselHeadline(ad_headline) {
      this.ad_headline = ad_headline;
    },
    // async setAdAccount(ad_account) {
    //   this.adAccountId = await ad_account.id.slice(4);
    //   await this.fetchCampaigns();
    //   await this.fetchAdImages();
    // },
    async createCampaign(body) {
      let combined_data = { ad_account_id: this.adAccountId, body: body };

      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "facebook/createFacebookCampaign",
          combined_data
        );
        this.$store.commit("makingApiRequest", false);
        if (this.facebook_campaign) {
          this.$notify({
            title: "Campaign created successfully!",
            group: "success",
          });

          // refetc campaign to update created campaign
          this.fetchCampaigns(this.adAccountId);
          this.$router.push("/facebook/create-campaign?tab=adset");
        }
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async updateCampaign(body) {
      let combined_data = {
        ad_account_id: this.adAccountId,
        campaign_id: this.campaignId,
        body: body,
      };
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "facebook/updateFacebookCampaign",
          combined_data
        );
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Campaign updated successfully!",
          group: "success",
        });
        await this.$router.push({
          name: "facebook-campaign",
          params: { id: this.campaignId },
        });
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    async createAdset(body) {
      if (!body.targeting.geo_locations && !body.targeting.custom_audiences) {
        this.$notify({
          title:
            "Location missing. Input a custom audience or choose country to set location!",
          group: "errors",
        });
        return;
      }

      let combined_data = {
        adAccountId: this.adAccountId,
        body: body,
      };

      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("facebook/createAdset", combined_data);
        this.$store.commit("makingApiRequest", false);

        if (this.facebook_single_adset) {
          this.$notify({
            title: "Adset created successfully!",
            group: "success",
          });

          this.$router.push("/facebook/create-campaign?tab=visuals");
        }
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    async updateAdset(body) {
      let combined_data = {
        adAccountId: this.adAccountId,
        adSetId: this.param_id,
        body: body,
      };

      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("facebook/updateAdset", combined_data);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Adset updated successfully!",
          group: "success",
        });

        this.$router.push({
          name: "facebook-ad-set",
          params: { id: this.facebook_single_adset.id },
        });
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    async postCreative(data) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("facebook/postFacebookAdCreative", data);
        this.$store.commit("makingApiRequest", false);
        await this.$notify({
          title: "Creative created successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },
    async postImageCreative({ ad_format, object_story_spec }) {
      let data = {
        object_story_spec: {
          object_story_spec: object_story_spec,
        },
        adAccountId: this.adAccountId,
      };

      await this.postCreative(data);
      await this.postPreviewOption(ad_format);
    },
    async postCarouselCreatives({ ad_format, object_story_spec }) {
      let data = {
        object_story_spec: {
          name: this.ad_headline,
          object_story_spec: object_story_spec,
        },
        adAccountId: this.adAccountId,
      };

      await this.postCreative(data);
      await this.postPreviewOption(ad_format);
    },
    async postPreviewOption(ad_format) {
      let combined_data = {
        ad_format: ad_format,
        adAccountId: this.adAccountId,
        creativeId: await this.facebook_ad_creative.id,
      };

      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("facebook/postPreviewOption", combined_data);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },
    async uploadVideo(video_data) {
      let req_body = {
        adAccountId: this.adAccountId,
        video_data: video_data,
      };
      delete req_body.video_data.image_url;
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("facebook/postAdVideo", req_body);
        this.$store.commit("makingApiRequest", false);
        await this.$notify({
          title: "Video uploaded successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },
    async uploadImage(bytes) {
      const body = {
        adAccountId: this.adAccountId,
        bytes: bytes,
      };

      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("facebook/postAdImages", body);
        this.$store.commit("makingApiRequest", false);
        await this.$notify({
          title: "Image uploaded successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },
    async postAd(ad_data) {
      let combined_data = {
        adAccountId: this.adAccountId,
        body: ad_data,
      };

      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("facebook/postAd", combined_data);
        this.$store.commit("makingApiRequest", false);
        await this.$notify({
          title: `${this.facebook_ad.name} ad created successfully!`,
          group: "success",
        });

        this.$router.push({
          name: "facebook-ad",
          params: { id: this.facebook_ad.id },
        });
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    async postRegions(regions) {
      this.regions_params.q = regions;

      try {
        await this.$store.dispatch(
          "facebook/getAllRegions",
          this.regions_params
        );
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
      }
    },
    async postEducationMajor(major) {
      this.majors_params.q = major;
      try {
        await this.$store.dispatch(
          "facebook/getAllEducationMajors",
          this.majors_params
        );
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
      }
    },

    async postJobTitles(job_title) {
      this.job_titles_params.q = job_title;
      try {
        await this.$store.dispatch(
          "facebook/getAllJobTitles",
          this.job_titles_params
        );
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
      }
    },

    async postZipInt(zip) {
      this.zips_params.q = zip;
      try {
        await this.$store.dispatch("facebook/getZips", this.zips_params);
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
      }
    },

    async postCity(city) {
      this.city_params.q = city;
      try {
        await this.$store.dispatch("facebook/getAllCities", this.city_params);
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
      }
    },
    async fetchAdsets(campaign) {
      if (campaign) {
        try {
          await this.$store.dispatch("facebook/getFacebookAdsetsByCampaign", {
            adAccountId: await campaign.account_id,
            campaignId: await campaign.id,
          });
        } catch (error) {
          this.$notify({
            title: error,
            group: "errors",
          });
        }
      }
    },

    async fetchAdImages() {
      try {
        await this.$store.dispatch("facebook/getAdImages", this.adAccountId);
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
      }
    },
  },
};
</script>
