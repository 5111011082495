<template>
  <v-row>
    <v-col
      ><h6 class="mb-2">Devices</h6>
      <v-combobox
        :items="devices"
        outlined
        dense
        v-model="selected_devices"
        multiple
      >
      </v-combobox
    ></v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    devices: ["Desktop", "Mobile"],
    selected_devices: "",
  }),
  watch: {
    selected_devices() {
      let i;
      let device_platforms = [];
      for (i = 0; i < this.selected_devices.length; i++) {
        device_platforms.push(this.selected_devices[i].toLowerCase());
      }
      this.$emit("emitDevicePlatforms", device_platforms);
    },
  },
};
</script>
