<template>
  <general-card>
    <v-card-text>
      <v-row>
        <v-list
          v-for="([title, subtitle], i) in placementOptions"
          :key="i"
          three-line
          flat
        >
          <v-list-item-group>
            <v-list-item>
              <template>
                <v-list-item-action>
                  <v-radio-group v-model="placementOption" dense>
                    <v-radio :value="i"></v-radio>
                  </v-radio-group>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ title }}
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    {{ subtitle }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-row>
      <transition name="slide-fade">
        <div v-if="placementOption == 1">
          <devices @emitDevicePlatforms="postDevicePlatforms($event)" />
          <platforms @emitPublisherPlatforms="postPublisherPlatforms($event)" />
          <asset-customization
            @emitFacebookPositions="postFacebookPositions($event)"
            @emitInstagramPositions="postInstagramPositions($event)"
            @emitAudienceNetworkPositions="postAudienceNetworkPositions($event)"
            @emitMessengerPositions="postMessengerPositions($event)"
          />
        </div>
      </transition>
    </v-card-text>
  </general-card>
</template>

<script>
import GeneralCard from "../../Layouts/Cards/GeneralCard.vue";
import Devices from "./Devices.vue";
import Platforms from "./Platforms.vue";
import AssetCustomization from "./AssetCustomization.vue";

export default {
  data: () => ({
    placementOption: null,
    placementOptions: [
      [
        "Automatic placements (recommended)",
        "Use automatic placements to maximise your budget and help show your ads to more people. Facebook's delivery system will allocate your ad set's budget across multiple placements based on where they're likely to perform best.",
      ],
      [
        "Manual placements",
        "Manually choose the places to show your ad. The more placements you select, the more opportunities you'll have to reach your target audience and achieve your business goals.",
      ],
    ],
    device_platforms: "",
    publisher_platforms: "",
    facebook_positions: [],
    instagram_positions: [],
    audience_network_positions: [],
    messenger_positions: [],
  }),
  components: {
    GeneralCard,
    Devices,
    Platforms,
    AssetCustomization,
  },
  methods: {
    postDevicePlatforms(devices) {
      this.device_platforms = devices;
      this.$emit("getSelectedDevicePlatforms", this.device_platforms);
    },
    postPublisherPlatforms(platforms) {
      this.publisher_platforms = platforms;
      this.$emit("postSelectedPublisherPlatforms", this.publisher_platforms);
    },
    postFacebookPositions(positions) {
      this.facebook_positions = positions;
      this.$emit("emitFacebookPositions", this.facebook_positions);
    },
    postInstagramPositions(positions) {
      this.instagram_positions = positions;
      this.$emit("emitInstagramPositions", this.instagram_positions);
    },
    postAudienceNetworkPositions(positions) {
      this.audience_network_positions = positions;
      this.$emit("emitAudienceNetworkPlacements", this.audience_network_positions);
    },
    postMessengerPositions(positions) {
      this.messenger_positions = positions;
      this.$emit("emitMessengerPlacements", this.messenger_positions);
    },
  },
};
</script>
