<template>
  <div>
    <image-uploader
      :dialog="dialog"
      :progress="progress"
      :ad_images="ad_images"
      @postAdCreative="postAdCreative($event)"
      @uploadImage="uploadImage($event)"
      @closeDialog="closeDialog($event)"
    />
    <v-row class="mt-5">
      <v-col>
        <h5 class="mb-3">Media</h5>
        <v-menu transition="scroll-y-reverse-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              small
              v-if="checked.length == 0"
            >
              <v-icon left>mdi-file-image</v-icon> Add Media
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="dialog = !dialog">
              <v-icon left small>mdi-image-multiple</v-icon>
              <v-list-item-title
                v-text="'Add image'"
                class="text-caption"
              ></v-list-item-title>
            </v-list-item>
          </v-list>

          <v-list>
            <v-list-item link>
              <v-icon left small>mdi-youtube</v-icon>
              <v-list-item-title
                v-text="'Add Video'"
                class="text-caption"
              ></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn small v-if="checked.length == 0">Create Video</v-btn>
        <p class="text-caption text--disabled" v-if="checked.length > 0">
          {{ checked.length }} placements
        </p>
      </v-col>
      <v-col class="d-flex justify-end" v-if="checked.length > 0">
        <v-btn class="mr-2" small
          >Edit <v-icon right>mdi-menu-down</v-icon></v-btn
        >
        <v-btn small><v-icon>mdi-delete</v-icon></v-btn>
      </v-col>
    </v-row>

    <v-row v-if="ad_creative">
      <v-col class="mt-2">
        <v-expansion-panels
          accordion
          tile
          v-for="(placement, i) in placements"
          :key="i"
        >
          <v-expansion-panel>
            <v-expansion-panel-header @click.native="expansionPanel()">
              <template v-slot:actions>
                <v-icon class="icon">mdi-menu-down</v-icon>
              </template>
              <span class="header d-flex align-center">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><h6>{{ placement.title }}</h6></v-list-item-title
                    >
                    <v-list-item-subtitle class="text-caption">{{
                      placement.subtitle
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-spacer></v-spacer>
                <v-checkbox v-model="checkbox" @click.native="check($event)">
                </v-checkbox>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              v-for="(item, i) in placement.options"
              :key="i"
            >
              <div class="d-flex justify-space-between">
                <span class="pl-10 text-caption">{{ item }}</span>
                <v-checkbox
                  v-model="checked"
                  :value="item"
                  @click.native="check($event)"
                ></v-checkbox>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ImageUploader from "./ImageUploader.vue";

export default {
  data: () => ({
    ad_creative: false,
    dialog: false,
    checkbox: true,
    checked: [],
    radioGroup: 1,
    switch1: true,
    placements: [
      {
        title: "Feeds, In-stream videos",
        subtitle: "6 placements",
        options: [
          "Facebook News Feed",
          "Facebook In-stream videos",
          "Facebook Marketplace",
          "Facebook Video feeds",
          "Instagram Feed",
          "Instagram Explore",
        ],
      },
      {
        title: "Stories and Reels, In-stream videos",
        subtitle: "5 placements",
        options: [
          "Facebook Stories",
          "Instagram In-stream videos",
          "Instagram Stories",
          "Instagram Reels",
          "Messenger Stories",
        ],
      },
      {
        title: "Search results, Instant Articles",
        subtitle: "2 placements",
        options: ["Facebook Instant Articles", "Facebook Search results"],
      },
    ],
  }),
  props: {
    ad_images: {
      type: Array,
    },
    progress: {
      type: Number,
    },
  },
  components: {
    ImageUploader,
  },
  methods: {
    closeDialog(boolean) {
      this.dialog = boolean;
    },
    postAdCreative({ hash, dialog, image_link }) {
      this.dialog = dialog;
      this.$emit("postAdCreative", { hash: hash, image_link: image_link });
    },
    uploadImage(bytes) {
      this.$emit("uploadImage", bytes);
    },
  },
};
</script>