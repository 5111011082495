<template>
  <v-row>
    <v-col>
      <h5>Destination</h5>
      <span class="text-caption"
        >Tell us where to send people immediately after they've tapped or
        clicked on your ad.</span
      >

      <v-list two-line flat>
        <v-list-item-group>
          <v-list-item three-line>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  value="instant_experience"
                  v-model="destination"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title> Instant Experience</v-list-item-title>
                <v-list-item-subtitle
                  >Send people to a fast-loading, mobile-optimised
                  experience.</v-list-item-subtitle
                >
              </v-list-item-content>
            </template>
          </v-list-item>

          <transition name="slide-x-reverse-transition">
            <div class="pl-15" v-if="destination == 'instant_experience'">
              <v-btn class="ml-3" small
                >Choose a template <v-icon right>mdi-menu-down</v-icon></v-btn
              >
              <v-combobox
                outlined
                label="Search for an existing Instant Experience"
                dense
                class="ml-3 my-5"
              ></v-combobox>
            </div>
          </transition>

          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  v-model="destination"
                  value="website"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Website</v-list-item-title>
                <v-list-item-subtitle
                  >Send people to your website.</v-list-item-subtitle
                >
              </v-list-item-content>
            </template>
          </v-list-item>
          <transition name="slide-x-reverse-transition">
            <div class="pl-15" v-if="destination == 'website'">
              <h5 class="ml-3 mt-3">See more URL</h5>
              <v-text-field
                outlined
                label="Type or paste the web page URL that you want people to visit"
                class="ml-3 mt-2 mb-10"
                v-model="primary_link"
              ></v-text-field>

              <div>
                <router-link to="/" class="text-caption ml-3">Build a URL parameter</router-link>
              </div>

              <h5 class="ml-3 mt-5">"See More" display link</h5>
              <v-text-field
                outlined
                dense
                label="Add a shortened link to help people recognise your w..."
                class="my-2 ml-3"
                v-model="secondary_link"
              ></v-text-field>
            </div>
          </transition>
        </v-list-item-group>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    destination: "",
    primary_link: "",
    secondary_link: ""
  }),
  watch: {
    primary_link() {
      this.$emit("emitPrimaryLink", this.primary_link);
    },
    secondary_link() {
      this.$emit("emitSecondaryLink", this.secondary_link);
    }
  },
  beforeDestroy() {
    this.destination = "";
    this.primary_link = "";
    this.secondary_link = "";
  }
};
</script>