<template>
  <v-form ref="form" lazy-validation v-model="valid">
    <v-col>
      <h3 class="mb-2">Special ad categories</h3>
      <p class="text-caption">
        Declare if your ads are related to credit, employment or housing, or about social
        issues, elections or politics. Requirements differ by country.
      </p>
      <h4 class="my-3">Categories<span class="error--text">*</span></h4>
      <v-select
        required
        :items="special_ad_categories"
        outlined
        dense
        append-icon="mdi-chevron-down"
        label="Special category"
        v-model="selected_category"
        multiple
        :rules="[
          (v) => !!v || 'Select a special category (Select none if there is none)!',
        ]"
        hide-details="auto"
        :menu-props="{ bottom: true, offsetY: true }"
      ></v-select>
    </v-col>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    selected_category: null,
    special_ad_categories: [
      "None",
      "Housing",
      "Credit",
      "Employment",
      "Issues Elections Politics",
    ],
  }),
  props: {
    isValid: Boolean,
    facebook_campaign: {
      type: Object,
    },
  },
  watch: {
    selected_category() {
      this.$emit("setSpecialAdCategory", this.selected_category);
    },

    isValid() {
      this.$refs.form.validate();
    },
  },
  mounted() {
    this.selected_category = this.facebook_campaign
      ? [this.facebook_campaign.special_ad_category]
      : null;
  },
  beforeDestroy() {
    this.selected_category = null;
    this.valid = true;
  },
};
</script>
