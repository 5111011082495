<template>
  <div>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col>
          <h4 class="mb-2">Campaign budget</h4>
          <v-select
            :items="periods"
            outlined
            dense
            append-icon="mdi-chevron-down"
            label="Duration"
            hide-details="auto"
          ></v-select>
          <div class="d-flex align-center mt-5">
            <h5 class="overline">Amount</h5>
            <v-spacer></v-spacer>
            <h2>${{ spentPerDay.toFixed(2) }}</h2>
          </div>
          <v-slider
            v-model="spentPerDay"
            :min="0"
            :max="1000"
            prepend-icon="mdi-currency-usd"
            class="align-center"
            hide-details
            thumb-color="primary"
            track-color="grey lighten-3"
          />
          <span class="text-caption text--disabled">
            Actual amount spent daily may vary.
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <h4 class="mb-2">Campaign Bid Strategy</h4>
      <v-select
        :items="bid_strategies"
        v-model="bid_strategy"
        outlined
        dense
        append-icon="mdi-chevron-down"
        :menu-props="{ top: true, offsetY: true }"
      ></v-select>
    </v-card-text>
  </div>
</template>

<script>
export default {
  data: () => ({
    periods: ["Daily", "Weekly", "General"],
    spentPerDay: 150,
    bid_strategies: ["Lowest cost", "Bid cap"],
    bid_strategy: null,
  }),
  mounted() {
    this.bid_strategy = this.bid_strategies[0];
  },
  beforeDestroy() {
    this.spentPerDay = 0;
    this.bid_strategy = null;
  },
};
</script>
