<template>
  <v-form v-model="valid" ref="form" lazy-validation class="campaign-form">
    <v-row>
      <v-col cols="7">
        <general-card class="pa-5 mb-3">
          <v-row>
            <v-col>
              <h5 class="mb-2">Ad name<span class="error--text">*</span></h5>
              <v-text-field
                v-model="name"
                class="pb-5 required"
                single-line
                label="Ad name"
                outlined
                required
                dense
                color="#3371E2"
                :rules="[(v) => !!v || 'Input an ad name to proceed!']"
              ></v-text-field>
            </v-col>
          </v-row>
        </general-card>
        <general-card class="pa-5 mb-3">
          <v-row>
            <v-col>
              <h5 class="mb-2">Identity</h5>
              <h6 class="mb-2">
                Facebook Page<span class="error--text">*</span>
              </h6>
              <v-select
                :items="facebook_client_pages"
                label="Select Facebook Page"
                outlined
                dense
                item-text="name"
                required
                item-value="id"
                v-model="facebook_page_id"
                :rules="[(v) => !!v || 'Select a Facebook page to proceed!']"
                class="required"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col>
              <h6 class="mb-2">Instagram account</h6>
              <v-select
                :items="instagram_accounts"
                label="Select Instagram account"
                outlined
                disabled
                dense
                item-text="name"
                item-value="id"
                v-model="instagram_account_id"
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="mt-3">
            <v-col>
              <h6 class="mb-2">
                Choose Campaign<span class="error--text">*</span>
              </h6>
              <v-select
                :items="facebook_campaigns"
                outlined
                dense
                item-text="name"
                return-object
                v-model="selected_campaign"
                :rules="[
                  (v) =>
                    !!v || 'Choose a campaign to generate available adsets!',
                ]"
                required
                class="required"
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="mt-3 pb-5">
            <v-col>
              <h6 class="mb-2">Adset<span class="error--text">*</span></h6>
              <v-select
                :items="facebook_adsets"
                label="Select campaign above to display it's adsets. Make sure the campaign has adset(s)"
                outlined
                dense
                item-text="name"
                item-value="id"
                v-model="selected_adset_id"
                :rules="[(v) => !!v || 'Select an adset to proceed!']"
                class="required"
              ></v-select>
            </v-col>
          </v-row>
        </general-card>

        <general-card class="pa-5 mb-3">
          <ad-setup
            @setAdFormat="setAdFormat($event)"
            @setValidity="setValidity($event)"
            :isValid="isValid"
            :facebook_adsets="facebook_adsets"
          />
        </general-card>

        <general-card class="pa-5 mb-3">
          <ad-creative
            @addDestination="addDestination($event)"
            @emitCarouselHeadline="emitCarouselHeadline($event)"
            @postAdCreative="postAdCreative($event)"
            @uploadImage="uploadImage($event)"
            @setValidity="setValidity($event)"
            @postVideoCreative="postVideoCreative($event)"
            @uploadVideo="uploadVideo($event)"
            @emitCarouselData="postCarouselData($event)"
            :ad_format="ad_format"
            :ad_images="ad_images"
            :facebook_adsets="facebook_adsets"
            :selected_adset_id="selected_adset_id"
            :facebook_page_id="facebook_page_id"
            :isValid="isValid"
            :selected_campaign="selected_campaign"
          />
        </general-card>

        <transition name="slide-x-transition">
          <general-card
            class="pa-5 mb-3"
            v-if="add_destination || ad_format == 'collection'"
          >
            <destination
              @emitPrimaryLink="getPrimaryLink($event)"
              @emitSecondaryLink="getSecondaryLink($event)"
            />
          </general-card>
        </transition>

        <general-card class="pa-5">
          <languages />

          <v-row class="mt-3">
            <v-col>
              <h6 class="mb-2">Status</h6>
              <v-select
                :items="adset_statuses"
                label="Set status"
                outlined
                dense
                v-model="ad_status"
              ></v-select>
            </v-col>
          </v-row>
        </general-card>
      </v-col>
      <v-col cols="5">
        <general-card class="pa-5">
          <ad-preview
            v-if="isFacebook"
            @emitPreviewOption="emitPreviewOption($event)"
            @emitInitialPreviewOption="setInitialPreviewOption($event)"
            :facebook_post_preview="facebook_post_preview"
            :key="component_key"
          />
          <creative-options v-else />
          <v-row>
            <v-col>
              <v-btn class="primary float-right" @click="postAd">
                Post Ad
              </v-btn>
            </v-col>
          </v-row>
        </general-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import GeneralCard from "../../Layouts/Cards/GeneralCard.vue";
import CreativeOptions from "../../NewCampaign/CreativeOptions.vue";
import AdPreview from "../AdPreview.vue";
import AdSetup from "./AdSetup.vue";
import AdCreative from "./AdCreative.vue";
import Destination from "./Destination.vue";
import Languages from "./Languages.vue";

export default {
  data: () => ({
    component_key: 0,
    instagram_accounts: ["Foo", "Bar", "Fizz", "Buzz"],
    name: "",
    facebook_page_id: "",
    primary_link: "",
    secondary_link: undefined,
    instagram_account_id: "",
    eCPM: null,
    row: null,
    budget: null,
    impressions: null,
    selectedDate: null,
    fCap: null,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    pixelURL: "",
    isFacebook: false,
    add_destination: false,
    ad_format: "",
    selected_adset_id: null,
    adset_statuses: ["Active", "Paused", "Deleted", "Archived"],
    ad_status: "Paused",
    preview_format: "Desktop Feed Standard",
    selected_campaign: null,
    valid: true,
    isValid: true,
    creative_data: null,
    error: null,
    names: [],
    carouselBody: null,
    length: null,
  }),
  props: {
    facebook_client_pages: {
      type: Array,
    },
    progress: {
      type: Number,
    },
    ad_images: {
      type: Array,
    },
    facebook_adsets: {
      type: Array,
    },
    facebook_ad_creative: {
      type: Object,
    },
    facebook_post_preview: {
      type: String,
    },
    facebook_campaigns: {
      type: Array,
    },
    latest_campaign: {
      type: Object,
    },
    carousel_creative: {
      type: Object,
    },
    ad_video: {
      type: String,
    },
  },
  components: {
    GeneralCard,
    CreativeOptions,
    AdPreview,
    AdSetup,
    AdCreative,
    Destination,
    Languages,
  },

  watch: {
    facebook_post_preview(newVal) {
      newVal ? (this.component_key += 1) : null;
    },
    selected_campaign() {
      this.selected_adset_id = null;
      // this.campaign_objective = this.selected_campaign.objective; //if campaign objective mobile and installs length should bbe greater than or equal to 3
      this.$emit("emitSelectedCampaign", this.selected_campaign);
      this.selected_adset_id = this.facebook_adsets.length
        ? this.facebook_adsets[0].id
        : null;
    },
    latest_campaign() {
      this.selected_campaign = this.latest_campaign;
      this.selected_adset_id = this.facebook_adsets.length
        ? this.facebook_adsets[0].id
        : null;
    },
  },
  mounted() {
    this.$route.name == "create-facebook-campaign"
      ? (this.isFacebook = true)
      : null;

    if (this.latest_campaign) {
      this.selected_campaign = this.latest_campaign;
    }

    if (this.facebook_adsets.length) {
      this.selected_adset_id = this.facebook_adsets[0].id;
    }
  },
  methods: {
    emitCarouselHeadline(ad_headline) {
      this.$emit("emitCarouselHeadline", ad_headline);
    },
    async validate() {
      await this.$refs.form.validate();
    },
    setValidity(validity) {
      this.valid = validity;
    },
    addDestination(add_destination) {
      this.add_destination = add_destination;
    },
    setAdFormat(ad_format) {
      this.ad_format = ad_format;
    },
    postAdCreative({ message, hash, image_link }) {
      this.creative_data = {
        ad_format: this.preview_format.toUpperCase().replaceAll(" ", "_"),
        object_story_spec: {
          page_id: this.facebook_page_id,
          link_data: {
            message: message,
            link: image_link,
            image_hash: hash,
            // call_to_action: {
            //   type: action,
            //   value: {
            //     link: this.secondary_link,
            //   },
            // },
          },
        },
      };

      this.$emit("postAdCreative", this.creative_data);
    },

    postCarouselData(carousel_body) {
      const object_story_spec = {
        link_data: carousel_body,
        page_id: this.facebook_page_id,
      };

      this.$emit("postCarouselCreatives", {
        ad_format: this.preview_format.toUpperCase().replaceAll(" ", "_"),
        object_story_spec: object_story_spec,
      });
    },
    postVideoCreative(video_data) {
      this.creative_data = {
        name: video_data.name,
        ad_format: "WATCH_FEED_HOME",
        object_story_spec: {
          page_id: this.facebook_page_id,
          video_data: {
            image_url: video_data.image_url,
            video_id: this.ad_video,
          },
        },
      };

      this.$emit("postAdCreative", this.creative_data);
    },
    emitPreviewOption(preview_option) {
      this.$emit("emitPreviewOption", preview_option);
    },
    uploadImage(bytes) {
      this.$emit("uploadImage", bytes);
    },
    uploadVideo(video_data) {
      this.$emit("uploadVideo", video_data);
    },
    getPrimaryLink(primary_link) {
      this.primary_link = primary_link;
    },
    getSecondaryLink(secondary_link) {
      this.secondary_link = secondary_link;
    },
    async postAd() {
      await this.validate();

      if (!this.facebook_ad_creative) {
        this.$notify({
          title: "Include a creative to proceed!",
          group: "errors",
        });

        return;
      }

      if (this.ad_format == "single") {
        if (
          !this.ad_format ||
          !this.creative_data.object_story_spec.link_data.message
        ) {
          this.isValid = false;
        }
      }

      if (!this.valid) return;

      const ad_data = {
        name: this.name,
        adset_id: this.selected_adset_id,
        creative: {
          creative_id: this.facebook_ad_creative.id,
        },
        status: this.ad_status.toUpperCase(),
      };
      this.$emit("postAd", ad_data);
    },
  },
  beforeDestroy() {
    this.valid = true;
    this.name = "";
    this.facebook_page_id = "";
    this.instagram_account_id = "";
    this.selected_campaign = null;
    this.selected_adset_id = null;
    this.ad_status = "Paused";
  },
};
</script>

<style>
/* #visuals .theme--light.v-divider {
  border-color: rgba(235, 235, 235, 0.3);
}

.v-input__control {
  height: 40px;
}

#calendar .v-input__icon {
  height: 24px !important;
  width: 0;
}

.no-space label {
  font-size: 0.7rem !important;
}

.no-space .v-icon.v-icon--dense {
  font-size: 0.7rem !important;
  color: rgba(112, 112, 112, 0.5) !important;
}

.row+.row {
  margin-top: 0;
} */
</style>
