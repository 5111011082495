<template>
  <v-form v-model="valid" class="campaign-form" ref="form" lazy-validation>
    <v-row>
      <v-col cols="7">
        <general-card>
          <v-card-title>
            <h6>Campaign objective<span class="error--text">*</span></h6>
          </v-card-title>
          <v-card-subtitle> Choose a campaign objective </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <objectives
              @chosenObjective="chosenObjective($event)"
              :facebook_campaign="facebook_campaign"
              class="mb-5"
            />
          </v-card-text>

          <div v-if="campaignGoal == 'ENGAGEMENT' || this.facebook_campaign">
            <v-divider></v-divider>
            <v-card-text class="mb-5">
              <v-row
                v-if="
                  campaignGoal == 'ENGAGEMENT' ||
                  this.facebook_campaign.objective == 'PAGE_LIKES'
                "
              >
                <v-col>
                  <h4 class="my-3">
                    Engagement type<span class="error--text">*</span>
                  </h4>
                  <v-radio-group
                    dense
                    v-model="engagementOption"
                    required
                    :rules="[
                      (v) => !!v || 'Select an engagement type to proceeed!',
                    ]"
                    hide-details
                  >
                    <v-radio
                      value="Post Engagement"
                      label="Post Engagement"
                      hide-details
                    ></v-radio>
                    <v-radio
                      value="Page Likes"
                      label="Page Likes"
                      hide-details
                    ></v-radio>
                    <v-radio
                      value="Event Responses"
                      label="Event Responses"
                      hide-details
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card-text>
          </div>

          <div v-if="campaignGoal == 'APP_INSTALLS'">
            <v-divider></v-divider>
            <v-card-text>
              <h4 class="my-3">
                App installs campaign type<span class="error--text">*</span>
              </h4>
              <v-list two-line flat>
                <v-list-item-group>
                  <v-list-item
                    v-for="([optionTitle, subTitle], i) in installOptions"
                    :key="i"
                  >
                    <v-list-item-action>
                      <v-radio-group
                        v-model="installationOption"
                        dense
                        :rules="[(v) => !!v || 'Select campaign type!']"
                      >
                        <v-radio :value="optionTitle"></v-radio>
                      </v-radio-group>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>{{ optionTitle }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        subTitle
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </div>

          <transition name="slide-fade">
            <div v-if="campaignGoal">
              <v-divider></v-divider>
              <v-card-text class="mb-5">
                <v-row>
                  <v-col>
                    <h4 class="my-3">
                      Campaign name<span class="error--text">*</span>
                    </h4>

                    <v-text-field
                      v-model="headline"
                      single-line
                      outlined
                      dense
                      label="Campaign Name"
                      hide-details="auto"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <div v-if="superAdmin && this.$route.name == 'create-facebook-campaign'">
                <v-card-text class="blue lighten-5 pb-10">
                  <!-- Tag Client -->
                  <v-row>
                    <v-col cols="12">
                      <span class="overline">Admin</span>
                    </v-col>
                    <v-col>
                      <h4 class="my-3">Tag Client</h4>
                      <v-select
                        v-model="tagged_client"
                        required
                        :items="clients"
                        item-text="client_name"
                        return-object
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        label="Select client"
                        hide-details="auto"
                        :menu-props="{ bottom: true, offsetY: true }"
                      ></v-select>
                    </v-col>
                    <!-- MediaPal Platform Fee -->

                    <v-col>
                      <h4 class="my-3">Platform Fee (%)</h4>
                      <v-text-field
                        v-model="platform_fee"
                        single-line
                        outlined
                        dense
                        :rules="percentageRules"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>

                    <!-- Agency Arbitrage Fee -->

                    <v-col>
                      <h4 class="my-3">Agency Arbitrage Fee (%)</h4>
                      <v-text-field
                        v-model="agency_arbitrage_fee"
                        single-line
                        outlined
                        dense
                        hide-details="auto"
                        :rules="percentageRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>
              <v-divider></v-divider>

              <v-row
                class="my-2"
                v-if="this.$route.name == 'create-facebook-campaign'"
              >
                <v-card-text>
                  <special-ad-categories
                    :facebook_campaign="facebook_campaign"
                    @setSpecialAdCategory="setSpecialAdCategory($event)"
                    :isValid="valid"
                  />
                </v-card-text>
              </v-row>

              <v-divider></v-divider>

              <v-card-text class="pb-10">
                <v-row>
                  <v-col>
                    <h4 class="my-3">Status</h4>
                    <v-select
                      v-model="status"
                      outlined
                      dense
                      label="Select Status"
                      :items="statuses"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :menu-props="{ bottom: true, offsetY: true }"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <v-row v-if="campaignGoal === 'ENGAGEMENT'">
                <v-card-text class="pb-10">
                  <v-col>
                    <h5 class="my-3">
                      Facebook Client Pages<span class="error--text">*</span>
                    </h5>
                    <v-select
                      v-model="facebook_page_id"
                      outlined
                      dense
                      required
                      :rules="[(v) => !!v || 'Client Page Is Required!']"
                      :items="facebook_client_pages"
                      hide-details="auto"
                      item-text="name"
                      item-value="id"
                    ></v-select>
                  </v-col>
                </v-card-text>
              </v-row>
              <v-divider></v-divider>

              <v-row>
                <v-card-text>
                  <campaign-details :campaignGoal="campaignGoal ? campaignGoal : null" />
                </v-card-text>
              </v-row>
            </div>
          </transition>
        </general-card>
      </v-col>
      <v-col cols="5">
        <general-card>
          <div v-if="campaignGoal !== 'APP_INSTALLS'">
            <transition name="slide-fade">
              <div>
                <v-card-title class="d-flex align-center py-2">
                  <h5>Campaign budget optimisation</h5>
                  <v-spacer></v-spacer>
                  <div>
                    <v-switch
                      v-model="optimizeBudget"
                      :label="`${optimizeBudget ? 'On' : 'Off'}`"
                      hide-details="auto"
                      class="mt-0"
                    ></v-switch>
                  </div>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <p class="text-caption">
                    Campaign budget optimisation will distribute your budget
                    across ad sets to get more results depending on your
                    delivery optimisation choices and bid strategy. You can
                    control spending on each ad set.
                  </p>
                </v-card-text>
              </div>
            </transition>
            <transition name="slide-fade">
              <estimated-results v-if="optimizeBudget" />
            </transition>
          </div>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              v-if="$route.name == 'create-facebook-campaign'"
              color="primary"
              depressed
              @click.stop="next"
              :disabled="!campaignGoal"
            >
              Next
            </v-btn>
            <v-btn
              v-else
              class="primary"
              depressed
              @click.stop="update"
              :disabled="!valid || !campaignGoal"
            >
              Update
            </v-btn>
          </v-card-actions>
        </general-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import GeneralCard from "../../Layouts/Cards/GeneralCard.vue";
import EstimatedResults from "./EstimatedResults.vue";
import Objectives from "./Objectives.vue";
import SpecialAdCategories from "./SpecialAdCategories.vue";
import CampaignDetails from "./CampaignDetails.vue";

export default {
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    percentageRules: [
      (v) => v >= 0 || "Percentage cannot be less than 1",
      (v) => v <= 100 || "Percentage cannot be greater than 100",
    ],
    platform_fee: null,
    agency_arbitrage_fee: null,
    tagged_client: null,
    tagged_client_id: null,
    valid: true,
    campaignGoal: null,
    headline: "",
    special_ad_categories: "",
    name: null,
    Status: "paused",
    optimizeBudget: false,
    eCPM: null,
    budget: null,
    impressions: null,
    radioGroup: 1,
    fCap: null,
    facebook_page_id: "",
    engagementOptions: ["Post Engagement", "Page Likes", "Event Responses"],
    installOptions: [
      [
        "Automated app ads",
        "Drive campaign performance and continue to sustain it over time.",
      ],
      ["App ads", "Manually set up and run your app campaigns."],
    ],
    installationOption: null,
    engagementOption: undefined,
    status: "PAUSED",
    statuses: [
      { text: "Active", value: "ACTIVE" },
      { text: "Archived", value: "ARCHIVED" },
      { text: "Deleted", value: "DELETED" },
      { text: "Paused", value: "PAUSED" },
    ],
    files: [
      {
        color: "grey lighten-2",
        icon: "mdi-thumb-up",
        subtitle:
          "Create a promotion to help more people find and like your Page.",
        title: "Get more Page likes",
      },
      {
        color: "grey lighten-2",
        icon: "mdi-thumb-up",
        subtitle:
          "Show your ad to people who are likely to send you a message on WhatsApp.",
        title: "Get more messages on WhatsApp",
      },
      {
        color: "grey lighten-2",
        icon: "mdi-phone",
        subtitle:
          "Show your ad to people who are likely to call your business.",
        title: "Get more calls",
      },
      {
        color: "grey lighten-2",
        icon: "mdi-cursor-default",
        subtitle:
          "Show your ad to people who are likely to click on a URL in it.",
        title: "Get more website visitors",
      },
      {
        color: "grey lighten-2",
        icon: "mdi-facebook-messenger",
        subtitle:
          "Show your ad to people who are likely to send you a message on Facebook.",
        title: "Get more messages on Messenger",
      },
      {
        color: "grey lighten-2",
        icon: "mdi-gesture-tap-button",
        subtitle:
          "Use a form to collect contact information from potential customers.",
        title: "Get more leads",
      },
    ],
  }),
  components: {
    GeneralCard,
    EstimatedResults,
    Objectives,
    SpecialAdCategories,
    CampaignDetails,
  },

  props: {
    facebook_client_pages: {
      type: Array,
    },
    facebook_campaign: {
      type: Object,
    },
    clients: {
      type: Array,
    },
  },
  async mounted() {
    await this.setHeadlineStatus();
  },
  computed: {
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
  },
  watch: {
    tagged_client() {
      this.tagged_client_id = this.tagged_client ? this.tagged_client.id : null;
    },
  },
  methods: {
    async setHeadlineStatus() {
      
      if (this.$route.name === "update-facebook-campaign") {
        this.status = this.facebook_campaign
          ? await this.facebook_campaign.status
          : "";
        this.headline = this.facebook_campaign
          ? await this.facebook_campaign.name
          : "";
        this.tagged_client_id = this.facebook_campaign
          ? await this.facebook_campaign.client_id
          : null;
        const client =
          (await this.clients) && this.clients.length
            ? this.clients.filter(
                (client) => client.id === this.tagged_client_id
              )
            : [];
        this.tagged_client = client[0];
        this.agency_arbitrage_fee = this.facebook_campaign
          ? await this.facebook_campaign.agency_arbitrage_fee
          : 0;
        this.platform_fee = this.facebook_campaign
          ? await this.facebook_campaign.platform_fee
          : 0;
      }
    },
    async validate() {
      await this.$refs.form.validate();
    },
    chosenObjective(goal) {
      this.campaignGoal = goal;
    },
    setSpecialAdCategory(special_ad_categories) {
      this.special_ad_categories = special_ad_categories;
    },
    async next() {
      await this.validate();

      if (!this.special_ad_categories) {
        this.valid = false;
        return;
      }

      if (!this.valid) return;

      let body = {
        client_id: this.tagged_client_id,
        agency_arbitrage_fee: this.agency_arbitrage_fee,
        platform_fee: this.platform_fee,
        facebook_body: {
          id: this.facebook_page_id,
          name: `${this.headline}`,
          objective:
            this.campaignGoal == "ENGAGEMENT"
              ? `POST_${this.campaignGoal.toUpperCase().replaceAll(" ", " ")}`
              : this.campaignGoal.toUpperCase().replaceAll(" ", " "),
          status: this.status,
          special_ad_categories: this.special_ad_categories,
          facebook_client_pages: this.facebook_client_pages,

          engagement_type: this.engagementOption
            ? this.engagementOption.toUpperCase().replaceAll(" ", "_")
            : undefined,
          optimizeBudget: this.optimizeBudget,
        },
      };

      this.$emit("create", body);
    },

    async update() {
      let body = {
        client_id: this.tagged_client_id,
        agency_arbitrage_fee: this.agency_arbitrage_fee,
        platform_fee: this.platform_fee,
        facebook_body: {
          id: this.facebook_page_id,
          name: `${this.headline}`,
          objective:
            this.campaignGoal == "ENGAGEMENT"
              ? `POST_${this.campaignGoal.toUpperCase().replaceAll(" ", " ")}`
              : this.campaignGoal.toUpperCase().replaceAll(" ", " "),
          status: this.status,
          special_ad_categories: this.special_ad_categories,
          facebook_client_pages: this.facebook_client_pages,

          engagement_type: this.engagementOption
            ? this.engagementOption.toUpperCase().replaceAll(" ", "_")
            : undefined,
          optimizeBudget: this.optimizeBudget,
        },
      };
      this.$emit("update", body);
    },
  },
  beforeMount() {
    this.engagementOption = null;
    this.installationOption = null;
    this.headline = "";
    this.status = "PAUSED";
    this.facebook_page_id = "";
    this.optimizeBudget = false;
    this.valid = true;
  },
};
</script>

<style>
#checkboxLabel {
  float: left;
}
</style>
