<template>
  <div class="section-height">
    <v-divider></v-divider>
    <v-row class="py-2 d-flex align-center">
      <v-col>
        <h4 class="text--disabled text-uppercase">Ad Preview</h4>
      </v-col>
      <v-col>
        <v-select
          outlined
          dense
          :items="preview_options"
          v-model="preview_option"
          label="Select preview option"
          :disabled="!facebook_post_preview"
        ></v-select>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <transition name="scroll-y-reverse-transition">
      <v-row class="py-2 d-flex align-center" v-if="facebook_post_preview">
        <v-col>
          <span v-html="facebook_post_preview"></span>
        </v-col>
      </v-row>
    </transition>
  </div>
</template>

<script>
export default {
  data: () => ({
    periods: ["Daily", "Weekly", "General"],
    clickThroughURL: "",
    cascadeClickURL: null,
    banner: null,
    brandingType: null,
    preview_option: "",
    preview_options: [
      "Audience Network Instream Video",
      "Audience Network Instream Video Mobile",
      "Audience Network Outstream Video",
      "Audience Network Rewarded Video",
      "Biz Disco Feed Mobile",
      "Desktop Feed Standard",
      "Facebook Reels Banner",
      "Facebook Reels Mobile",
      "Facebook Reels Sticker",
      "Facebook Story Mobile",
      "Facebook Story Sticker Mobile",
      "Instagram Explore Contextual",
      "Instagram Explore Immersive",
      "Instagram Feed Web",
      "Instagram Feed Web M Site",
      "Instagram Reels",
      "Instagram Reels Overlay",
      "Instagram Shop",
      "Instagram Standard",
      "Instagram Story",
      "Instant Article Recirculation Ad",
      "Instant Article Standard",
      "Instream Banner Desktop",
      "Instream Banner Mobile",
      "Instream Video Desktop",
      "Instream Video Image",
      "Instream Video Mobile",
      "Job Browser Desktop",
      "Job Browser Mobile",
      "Marketplace Mobile",
      "Messenger Mobile Inbox Media",
      "Messenger Mobile Story Media",
      "Mobile Banner",
      "Mobile Feed Basic",
      "Mobile Feed Standard",
      "Mobile Fullwidth",
      "Mobile Interstitial",
      "Mobile Medium Rectangle",
      "Mobile Native",
      "Right Column Standard",
      "Suggested Video Desktop",
      "Suggested Video Mobile",
      "Watch Feed Home",
      "Watch Feed Mobile",
    ],
  }),
  props: {
    facebook_post_preview: {
      type: String,
    },
  },
  watch: {
    preview_option() {
      this.$emit(
        "emitPreviewOption",
        this.preview_option.toUpperCase().replaceAll(" ", "_")
      );
    },
  },
  beforeDestroy() {
    this.preview_option = "";
  }
};
</script>
