<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-card-title class="py-2">
      <h5>Budget & Schedule</h5>
    </v-card-title>
    <v-divider></v-divider>

    <v-row class="d-flex align-center">
      <v-col>
        <v-card-text>
          <h5 class="mb-3">Budget<span class="error--text">*</span></h5>

          <v-select
            :items="periods"
            outlined
            dense
            append-icon="mdi-chevron-down"
            label="Duration"
            v-model="duration_type"
            disabled
            hide-details
          ></v-select>
          <div class="d-flex align-center mt-5">
            <h5 class="overline">Amount</h5>
            <v-spacer></v-spacer>
            <h2>${{ spent_per_day.toFixed(2) }}</h2>
          </div>
          <v-slider
            v-model="spent_per_day"
            :min="0"
            :max="1000"
            prepend-icon="mdi-currency-usd"
            class="align-center"
            hide-details
            thumb-color="primary"
            track-color="grey lighten-3"
            :rules="[(v) => !!v || 'Please input budget to proceed!']"
          />
          <span class="text-caption text--disabled">
            Actual amount spent daily may vary.
          </span>
        </v-card-text>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    periods: ["Daily budget", "Lifetime budget"],
    spent_per_day: 0,
    duration_type: "Daily budget",
    valid: true,
  }),
  props: {
    facebook_single_adset: {
      type: Object,
    },
    isValid: {
      type: Boolean,
    },
  },
  watch: {
    duration_type() {
      this.$emit("setDurationType", this.duration_type);
    },
    spent_per_day() {
      this.$emit("setSpend", this.spent_per_day);
    },
    facebook_single_adset() {
      this.spent_per_day = this.facebook_single_adset
        ? parseInt(this.facebook_single_adset.daily_budget) / 100
        : "";
    },
    async isValid() {
      this.$route.name !== "update-facebook-adset"
        ? await this.$refs.form.validate()
        : null;
      this.$emit("setValidity", this.valid);
    },
  },
  beforeDestroy() {
    this.valid = true;
    this.duration_type = "Daily Budget";
    this.spent_per_day = 0;
  },
};
</script>
