<template>
  <div>
    <v-card-text>
      <h5 class="mb-3">Schedule</h5>

      <v-row
        v-if="
          this.$route.name == 'create-facebook-campaign' ||
          this.$route.name == 'create-user-list'
        "
      >
        <v-col cols="6">
          <h4
            v-if="
              this.$route.name == 'create-facebook-campaign' ||
              this.$route.name == 'create-user-list'
            "
            class="mb-3"
          >
            Start date
            <span class="text--disabled" v-if="this.$route.name == 'create-user-list'"
              >(Optional)</span
            >
          </h4>
          <v-menu
            ref="start_date_menu_ref"
            v-model="start_date_menu"
            :close-on-content-click="false"
            :return-value.sync="start_date"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                dense
                v-model="start_date"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker v-model="start_date" scrollable>
              <v-spacer></v-spacer>
              <div class="px-2 pb-2">
                <v-btn text color="primary" @click="start_date_menu = false" class="mr-3">
                  Cancel
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  @click="$refs.start_date_menu_ref.save(start_date)"
                >
                  OK
                </v-btn>
              </div>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6">
          <div class="d-flex align-center mb-2">
            <v-checkbox v-model="checkbox" dense hide-details class="mt-0"> </v-checkbox>
            <h4 class="mt-1">
              Set End Date <span class="text--disabled">(Optional)</span>
            </h4>
          </div>
          <div>
            <v-menu
              ref="end_date_menu_ref"
              v-model="end_date_menu"
              :close-on-content-click="false"
              :return-value.sync="end_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="!checkbox"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="end_date"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!checkbox"
                ></v-text-field>
              </template>
              <v-date-picker v-model="end_date" scrollable>
                <v-spacer></v-spacer>
                <div class="px-2 pb-2">
                  <v-btn text color="primary" @click="end_date_menu = false" class="mr-3">
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    depressed
                    @click="$refs.end_date_menu_ref.save(end_date)"
                  >
                    OK
                  </v-btn>
                </div>
              </v-date-picker>
            </v-menu>
            <v-row class="mt-2">
              <v-col v-if="!this.$route.name == 'create-user-list'">
                <v-dialog
                  ref="end_time_dialog_ref"
                  v-model="end_time_dialog"
                  :return-value.sync="end_time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_time"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      dense
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-time-picker v-if="end_time_dialog" v-model="end_time" full-width>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="end_time_dialog = false"
                      class="mr-3"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      depressed
                      color="primary"
                      @click="$refs.end_time_dialog_ref.save(end_time)"
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>
<script>
export default {
  data: () => ({
    start_date_menu: false,
    end_date_menu: false,
    start_time_dialog: false,
    end_time_dialog: false,
    start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    end_date:
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 8) +
      (
        Number(
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(8, 2)
        ) + 1
      ).toString(),
    start_time: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(12, 4),
    end_time: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(12, 4),
    checkbox: false,
    beginning: "",
  }),
  watch: {
    start_date() {
      this.$emit("emitstartdate", this.start_date);
    },
    end_date() {
      this.$emit("emitenddate", this.end_date);
    },
    start_time() {
      this.$emit("emitstarttime", this.start_time);
    },
    end_time() {
      this.$emit("emitendtime", this.end_time);
    },
    // facebook_single_adset() {
    //   if (this.facebook_single_adset) this.end_date = this.facebook_single_adset.end_time ? this.facebook_single_adset.end_time : this.end_time; // Not tested
    // }
  },
  methods: {
    fullstartdate() {
      this.start_date.split("-");
    },
  },
  mounted() {
    this.$emit("emitstartdate", this.start_date);
    this.$emit("emitstarttime", this.start_time);
  },
  props: {
    facebook_single_adset: {
      type: Object,
    },
  },
};
</script>
