<template>
  <v-form lazy-validation class="campaign-form" v-model="valid" ref="form">
    <v-row>
      <v-col>
        <h5 class="mb-2">Ad setup</h5>
        <v-select
          v-model="selected_setup"
          :items="ad_setups"
          outlined
          dense
          item-text="title"
          item-value="value"
        >
          <template v-slot:item="{ active, item, attrs, on }">
            <v-list-item
              v-on="on"
              v-bind="attrs"
              :default="{ active }"
              two-line
            >
              <v-list-item-action>
                <v-radio-group v-model="selected_setup">
                  <v-radio :value="item.value"></v-radio>
                </v-radio-group>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>

        <h6 class="mt-5">Format<span class="error--text">*</span></h6>
        <span class="text-caption mb-2"
          >Choose how you'd like to structure your ad.</span
        >
        <v-list v-for="(format, i) in formats" :key="i">
          <v-list-item three-line>
            <v-list-item-action>
              <v-radio-group
                v-model="selected_format"
                :rules="[(v) => !!v || 'Select an ad format to continue!']"
                required
              >
                <v-radio :value="format.value"></v-radio>
              </v-radio-group>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ format.title }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ format.subtitle }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    ad_setups: [
      { title: "Create Ad", value: "create" },
      { title: "Use existing post", value: "existing" },
      { title: "Use Creative Hub Mockup", value: "mockup" },
    ],
    selected_setup: "create",
    formats: [
      {
        title: "Single image",
        subtitle: "A single image ad",
        value: "single",
      },
      {
        title: "Single Video",
        subtitle: "A video ad (must be less than 1 GB in size)",
        value: "video",
      },
      {
        title: "Carousel",
        subtitle: "Two or more scrollable images or videos",
        value: "carousel",
      },
      {
        title: "Collection",
        subtitle:
          "A group of items that opens into a full-screen mobile experience",
        value: "collection",
      },
    ],
    selected_format: null,
  }),
  watch: {
    selected_format() {
      this.$emit("setAdFormat", this.selected_format);
    },
    isValid() {
      this.validate();
      this.$emit("setValidity", this.valid);
    },
  },
  props: {
    isValid: Boolean,
  },
  methods: {
    async validate() {
      await this.$refs.form.validate();
    },
  },
  beforeDestroy() {
    this.valid = true;
    this.selected_setup = "create";
    this.selected_format = null;
  }
};
</script>