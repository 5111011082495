<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on }">
      <v-btn class="success" dark v-on="on">Post Video By URL</v-btn>
    </template>

    <v-card>
      <v-card-title class="headline green lighten-1 white--text" primary-title
        >Upload Video By Url</v-card-title
      >
      <v-col>
        <v-col>
          <h6 class="my-3">Name</h6>
          <v-text-field
            class="mb-2"
            outlined
            v-model="name"
            label="Name of your video"
          ></v-text-field></v-col
        ><v-col>
          <h6 class="my-3">Video Url</h6>
          <v-text-field
            outlined
            v-model="file_url"
            label="A URL to your Video Asset"
          ></v-text-field
        ></v-col>
        <v-col>
          <h6 class="my-3">Thumbnail Url</h6>
          <v-text-field
            outlined
            v-model="image_url"
            label="A Thumbnail for your Video Asset"
          ></v-text-field
        ></v-col>

        <v-col>
          <v-row justify="space-between" class="mt-3">
            <v-btn
              small
              class="ml-3"
              @click="uploadVideo({ name, file_url, image_url })"
              >Submit Urls</v-btn
            >
            <v-btn
              small
              class="success mr-3"
              @click="postVideoCreative({ name, image_url })"
              >Save and Continue</v-btn
            >
          </v-row></v-col
        >
      </v-col>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    name: "",
    file_url: "",
    image_url: "",
    dialog: false,
  }),
  methods: {
    uploadVideo({ name, file_url, image_url }) {
      let video_data = { name, file_url, image_url };
      this.$emit("uploadVideo", video_data);
    },
    postVideoCreative({ name, image_url }) {
      let video_data = { name, image_url };
      this.$emit("postVideoCreative", video_data);
      this.dialog = false;
    },
  },
};
</script>