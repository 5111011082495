<template>
  <v-form ref="form" lazy-validation v-model="valid">
    <general-card>
      <v-card-title class="d-flex">
        <h5>Audience</h5>
        <v-spacer></v-spacer>

        <v-btn depressed secondary @click="saved_audience = !saved_audience">{{
          saved_audience ? "Define Own Audience" : "Use Saved Audience"
        }}</v-btn>
      </v-card-title>
      <v-card-subtitle> Define who you want to see your ads </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="py-2 d-flex align-center" v-if="saved_audience">
          <v-col cols="3">
            <h5>Custom audience<span class="error--text">*</span></h5>
          </v-col>
          <v-col cols="9">
            <v-row>
              <v-col>
                <v-combobox
                  outlined
                  v-model="custom_audience"
                  :items="custom_audiences"
                  dense
                  multiple
                  item-text="name"
                  item-value="id"
                  required
                  :rules="[(v) => !!v || 'Audience targeting is required!']"
                  label="Select Custom Audience"
                  hide-details="auto"
                ></v-combobox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <h5>Age</h5>
          </v-col>
          <v-col cols="9" class="d-flex justify-space-between">
            <v-row>
              <v-col cols="6">
                <v-select
                  :items="age_range"
                  outlined
                  dense
                  v-model="age_min"
                  required
                  append-icon="mdi-chevron-down"
                  label="From Age"
                  hide-details="auto"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  :items="age_range"
                  outlined
                  dense
                  v-model="age_max"
                  required
                  append-icon="mdi-chevron-down"
                  label="To Age"
                  hide-details="auto"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col
            ><h4>
              Select Country, Region, City, Zip or Custom Location Targeting
            </h4></v-col
          >
        </v-row>
        <v-row class="py-2 d-flex align-center">
          <v-col cols="3">
            <h5>Locations</h5>
          </v-col>
          <v-col cols="9">
            <v-combobox
              v-model="selected_location"
              :items="location_options"
              label="Choose location"
              outlined
              dense
              item-text="title"
              item-value="value"
              multiple
              hide-details=""
            >
              <!-- <template v-slot:item="{ active, item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs" :default="{ active }" two-line>
                <v-list-item-action>
                  <v-radio-group v-model="selected_radio">
                    <v-radio :value="item.value"></v-radio>
                  </v-radio-group>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.subtitle }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template> -->
            </v-combobox>
          </v-col>
        </v-row>
        <v-row v-if="!regions && !city && !zip">
          <v-col cols="3">
            <h5>Countries<span class="error--text">*</span></h5>
          </v-col>
          <v-col cols="9">
            <v-select
              v-model="countries"
              :items="all_countries"
              label="Search or browse manually"
              outlined
              dense
              item-text="name"
              item-value="key"
              required
              :rules="[(v) => !!v || 'Countries targeting is required!']"
              multiple
              chips
              clearable
              small-chips
              hide-selected
              @change="display_map = true"
              hide-details="auto"
              :menu-props="{ bottom: true, offsetY: true }"
            >
            </v-select>
          </v-col>
        </v-row>
        <!--Regions -->
        <v-row v-if="!countries && !city && !zip">
          <v-col cols="3">
            <h5>Regions</h5>
          </v-col>
          <v-col class="d-flex">
            <v-text-field
              v-model="regions"
              outlined
              dense
              label="Type in region"
              class="mr-1"
              hide-details="auto"
            ></v-text-field>
            <v-select
              :items="all_regions"
              v-model="selected_region"
              label="Search or browse manually"
              outlined
              dense
              item-text="name"
              item-value="key"
              multiple
              chips
              clearable
              small-chips
              hide-selected
              @change="display_map = true"
              :disabled="!all_regions.length"
              hide-details="auto"
              :menu-props="{ bottom: true, offsetY: true }"
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- Cities -->
        <v-row v-if="!countries && !regions && !zip">
          <v-col cols="3">
            <h5>Cities</h5>
          </v-col>
          <v-col class="d-flex">
            <v-text-field
              outlined
              dense
              label="Type in city"
              v-model="city"
              class="mr-1"
              hide-details="auto"
            ></v-text-field>
            <v-select
              :items="all_cities"
              v-model="selected_cities"
              label="Search or browse manually"
              outlined
              dense
              item-text="name"
              item-value="key"
              multiple
              chips
              clearable
              small-chips
              hide-selected
              @change="display_map = true"
              :disabled="!all_cities.length"
              hide-details="auto"
              :menu-props="{ bottom: true, offsetY: true }"
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- Zips -->
        <v-row
          class="d-flex justify-end pb-2"
          v-if="!countries && !regions && !city"
        >
          <v-col cols="3">
            <h5>Zip Codes</h5>
          </v-col>
          <v-col class="d-flex">
            <v-text-field
              outlined
              dense
              label="Type in zip digit"
              v-model="zip"
              class="mr-1"
              hide-details="auto"
            ></v-text-field>
            <v-select
              :items="all_zips"
              v-model="selected_zips"
              label="Search or browse manually"
              outlined
              dense
              item-text="primary_city"
              item-value="id"
              multiple
              chips
              clearable
              small-chips
              hide-selected
              @change="display_map = true"
              :disabled="!all_zips.length"
              hide-details="auto"
              :menu-props="{ bottom: true, offsetY: true }"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <h5>Gender</h5>
          </v-col>
          <v-col cols="9" id="custom">
            <v-btn-toggle v-model="gender">
              <v-btn value="all" small> All </v-btn>

              <v-btn value="female" small> Female </v-btn>

              <v-btn value="male" small> Male </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-text
        v-if="countries || regions || city || zip || custom_audience.length"
      >
        <v-row>
          <v-col cols="12">
            <h5>Demographic Targeting</h5>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <v-select
                  v-model="selected_interests"
                  label="Add interests"
                  outlined
                  dense
                  :items="available_interests"
                  item-text="name"
                  item-value="id"
                  multiple
                  chips
                  clearable
                  small-chips
                  hide-selected
                  hide-details="auto"
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-select>
              </v-col>
              <v-col>
                <v-combobox
                  v-model="behaviors"
                  label="Add behaviors"
                  outlined
                  dense
                  :items="available_behaviors"
                  item-text="name"
                  item-value="id"
                  multiple
                  chips
                  clearable
                  small-chips
                  hide-selected
                  hide-details="auto"
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex">
                <v-text-field
                  outlined
                  dense
                  label="Type in education major"
                  v-model="major"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-select
                  label="Search or browse manually"
                  outlined
                  dense
                  v-model="selected_major"
                  :items="all_majors"
                  item-text="name"
                  item-value="id"
                  multiple
                  chips
                  clearable
                  small-chips
                  hide-selected
                  :disabled="!all_majors.length"
                  hide-details="auto"
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  dense
                  v-model="job_title"
                  label="Add Job Title"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-select
                  outlined
                  dense
                  label="Search or browse manually"
                  v-model="selected_job_titles"
                  :items="all_job_titles"
                  item-text="name"
                  item-value="id"
                  multiple
                  chips
                  clearable
                  small-chips
                  hide-selected
                  :disabled="!all_job_titles.length"
                  hide-details="auto"
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="life_events"
                  label="Add lIfe Events"
                  outlined
                  dense
                  :items="all_life_events"
                  item-text="name"
                  item-value="id"
                  multiple
                  chips
                  clearable
                  small-chips
                  hide-selected
                  hide-details="auto"
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="industries"
                  label="Add Industries"
                  outlined
                  dense
                  :items="all_industries"
                  item-text="name"
                  item-value="id"
                  multiple
                  chips
                  clearable
                  small-chips
                  hide-selected
                  hide-details="auto"
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="income"
                  label="Add Incomes"
                  outlined
                  dense
                  :items="all_incomes"
                  item-text="name"
                  item-value="id"
                  multiple
                  chips
                  clearable
                  small-chips
                  hide-selected
                  hide-details="auto"
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <h5>User OS</h5>
          </v-col>
          <v-col>
            <v-select
              v-model="user_os"
              label="Add User OS"
              outlined
              dense
              :items="all_user_os"
              item-text="platform"
              item-value="id"
              multiple
              chips
              clearable
              small-chips
              hide-selected
              hide-details="auto"
              :menu-props="{ bottom: true, offsetY: true }"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <h5>Languages</h5>
          </v-col>
          <v-col>
            <v-select
              v-model="selected_language"
              label="Search languages"
              outlined
              dense
              :items="all_languages"
              item-text="name"
              item-value="key"
              multiple
              chips
              clearable
              small-chips
              hide-selected
              hide-details="auto"
              :menu-props="{ bottom: true, offsetY: true }"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </general-card>
  </v-form>
</template>

<script>
import GeneralCard from "../../Layouts/Cards/GeneralCard.vue";

export default {
  data: () => ({
    valid: true,
    age_range: [],
    showLocationSet: true,
    selected_language: [],
    selected_radio: null,
    demographics: null,
    gender: null,
    max_age: 65,
    min_age: 18,
    age_min: "",
    age_max: "",
    display_map: false,
    countries: null,
    regions: "",
    city: "",
    selected_major: "",
    industries: [],
    major: "",
    selected_cities: [],
    selected_job_titles: [],
    zip: "",
    employers: "",
    selected_zips: [],
    selected_employer: "",
    latitude: "",
    longitude: "",
    job_title: "",
    specific_locations: [],
    selected_location: null,
    selected_region: [],
    custom_audience: [],
    selected_interests: "",
    behaviors: "",
    life_events: [],
    income: [],
    user_os: [],
    saved_audience: false,
    location_options: [
      {
        title: "People living in this location",
        subtitle: "People whose home is within the selected area.",
        value: "home",
      },
      {
        title: "People recently in this location",
        subtitle:
          "People whose most recent location is within the selected area.",
        value: "recent",
      },
      {
        title: "People travelling in this location",
        subtitle:
          "People whose most recent location is within the selected area but whose home is more than 125 mi/200 km away.",
        value: "travel_in",
      },
    ],
    cleaned_locations: null,
  }),
  // data: function () {
  //   return initialState();
  // },
  props: {
    custom_audiences: Array,
    all_countries: {
      type: Array,
    },
    all_regions: {
      type: Array,
    },
    all_cities: {
      type: Array,
    },
    all_zips: {
      type: Array,
    },
    all_languages: {
      type: Array,
    },
    available_interests: {
      type: Array,
    },
    available_behaviors: {
      type: Array,
    },
    available_work: {
      type: Array,
    },
    available_demographics: {
      type: Array,
    },
    all_life_events: {
      type: Array,
    },
    all_industries: {
      type: Array,
    },
    all_incomes: {
      type: Array,
    },
    all_majors: {
      type: Array,
    },
    all_employers: {
      type: Array,
    },
    all_job_titles: {
      type: Array,
    },
    all_user_os: {
      type: Array,
    },
    facebook_single_adset: {
      type: Object,
    },
    isValid: {
      type: Boolean,
    },
  },
  components: {
    GeneralCard,
  },
  methods: {
    generateNumbers() {
      for (let x = 13; x <= 65; x++) {
        this.age_range.push(x);
      }
    },
    // resetDataObject() {
    //   Object.assign(this.$data, initialState());
    // },
  },
  mounted() {
    this.generateNumbers();
  },
  watch: {
    async isValid() {
      await this.$refs.form.validate();
      this.$emit("emitValidity", this.valid);
    },
    age_max(age_max) {
      if (age_max > this.max_age) {
        alert(`Please choose an age less than ${this.max_age}`);
      }
      this.$emit("emitMaxAge", this.age_max);
    },
    age_min(age_min) {
      if (age_min < this.min_age) {
        alert(`Please choose an age more than ${this.min_age}`);
      }
      this.$emit("emitMinAge", this.age_min);
    },
    gender() {
      if (this.gender === "female") {
        let female = [2];
        this.$emit("setGender", female);
      }
      if (this.gender === "male") {
        let male = [1];
        this.$emit("setGender", male);
      }
      if (this.gender === "all") {
        let all = [1, 2];
        this.$emit("setGender", all);
      }
    },
    countries(oldVal, newVal) {
      //prefilled value for updating read and is not undefined however when a new country is added to
      //update country targeting the new value is passed as an object and not the country code string
      if (this.$route.name == "create-facebook-campaign") {
        let country = this.countries;
        let allcountrykeys = [];
        if (country.length) {
          for (let i = 0, len = country.length; i < len; i++) {
            allcountrykeys.push(country[i].key);
          }
        }
        this.$emit("setCountries", allcountrykeys);
      } else {
        let result = newVal ? oldVal.map((country) => country.key) : oldVal;
        if (newVal && this.facebook_single_adset)
          result =
            newVal.length ==
            this.facebook_single_adset.targeting.geo_locations.countries.length
              ? result
                  .concat(
                    this.facebook_single_adset.targeting.geo_locations.countries
                  )
                  .filter((country) => country !== undefined)
              : result.filter((country) => country !== undefined);
        this.$emit("setCountries", result);
      }
    },
    regions() {
      this.$emit("emitRegions", this.regions);
    },
    major() {
      this.$emit("emitMajor", this.major);
    },
    job_title() {
      this.$emit("emitJobTitles", this.job_title);
    },
    employer() {
      this.$emit("emitEmployer", this.employers);
    },
    custom_audience() {
      if (this.$route.name == "create-facebook-campaign") {
        let audience = this.custom_audience;
        let all = [];
        if (audience) {
          for (let i = 0, len = audience.length; i < len; i++) {
            let audienceObj = {};
            audienceObj.id = audience[i].id;
            all.push(audienceObj);
          }
        }
        this.$emit("emitCustomAudience", all);
      } else {
        this.$emit("emitCustomAudience", this.custom_audience);
      }
    },
    selected_location() {
      if (this.selected_location !== null) {
        this.cleaned_locations = this.selected_location.length
          ? this.selected_location.map((location) => location.value)
          : null;
        if (this.cleaned_locations.length > 1) {
          if (this.cleaned_locations.includes("travel_in")) return;
        }
        this.$emit("emitSelectedLocations", this.cleaned_locations);
      }
    },
    city() {
      this.$emit("emitCity", this.city);
    },
    selected_cities() {
      if (this.$route.name == "create-facebook-campaign") {
        let mycities = this.selected_cities;
        let allcities = [];
        if (mycities) {
          for (let i = 0, len = mycities.length; i < len; i++) {
            let cityObj = {};
            cityObj.key = mycities[i].key;
            allcities.push(cityObj);
          }
        }
        this.$emit("emitSelectedCities", allcities);
      } else {
        this.$emit("emitSelectedCities", this.selected_cities);
      }
    },
    selected_language() {
      if (this.$route.name == "create-facebook-campaign") {
        let languages = this.selected_language;
        let locales = [];
        if (languages) {
          for (let i = 0, len = languages.length; i < len; i++) {
            locales.push(languages[i].key);
          }
        }
        this.$emit("emitLanguage", locales);
        return;
      }

      this.$emit("emitLanguage", this.selected_language);
    },
    zip() {
      this.$emit("emitZip", this.zip);
    },
    selected_region() {
      if (this.$route.name == "create-facebook-campaign") {
        let myregions = this.selected_region;
        let allregions = [];
        if (myregions) {
          for (let i = 0, len = myregions.length; i < len; i++) {
            let regionObj = {};
            regionObj.key = myregions[i].key;
            allregions.push(regionObj);
          }
        }
        this.$emit("emitSelectedRegion", allregions);
        return;
      }

      this.$emit("emitSelectedRegion", this.selected_region);
    },
    selected_major() {
      if (this.$route.name == "create-facebook-campaign") {
        let mymajors = this.selected_major;
        let allmajors = [];
        if (mymajors) {
          for (let i = 0, len = mymajors.length; i < len; i++) {
            let majorObj = {};
            majorObj.id = mymajors[i].id;
            majorObj.name = mymajors[i].name;
            allmajors.push(majorObj);
          }
        }
        this.$emit("emitSelectedMajor", allmajors);
        return;
      }

      this.$emit("emitSelectedMajor", this.selected_major);
    },
    selected_zips() {
      if (this.$route.name == "create-facebook-campaign") {
        let myzips = this.selected_zips;
        let allzips = [];
        if (myzips) {
          for (let i = 0, len = myzips.length; i < len; i++) {
            let zipObj = {};
            zipObj.key = myzips[i].key;
            allzips.push(zipObj);
          }
        }
        this.$emit("emitSelectedZips", allzips);
        return;
      }

      this.$emit("emitSelectedZips", this.selected_zips);
    },
    behaviors() {
      if (this.$route.name == "create-facebook-campaign") {
        let behaves = this.behaviors;
        let allbehaviours = [];
        if (behaves) {
          for (let i = 0, len = behaves.length; i < len; i++) {
            let behaveObj = {};
            behaveObj.name = behaves[i].name;
            behaveObj.id = behaves[i].id;
            allbehaviours.push(behaveObj);
          }
        }
        this.$emit("emitBehaviors", allbehaviours);
        return;
      }

      this.$emit("emitBehaviors", this.behaviors);
    },
    selected_interests() {
      if (this.$route.name == "create-facebook-campaign") {
        let interest = this.selected_interests;
        let allinterests = [];
        if (interest) {
          for (let i = 0, len = interest.length; i < len; i++) {
            let interestObj = {};
            interestObj.name = interest[i].name;
            interestObj.id = interest[i].id;
            allinterests.push(interestObj);
          }
        }
        this.$emit("emitInterests", allinterests);
        return;
      }

      this.$emit("emitInterests", this.selected_interests);
    },
    life_events() {
      if (this.$route.name == "create-facebook-campaign") {
        let lifeevents = this.life_events;
        let allevents = [];
        if (lifeevents) {
          for (let i = 0, len = lifeevents.length; i < len; i++) {
            let eventsObj = {};
            eventsObj.name = lifeevents[i].name;
            eventsObj.id = lifeevents[i].id;
            allevents.push(eventsObj);
          }
        }
        this.$emit("emitLifeEvents", allevents);
        return;
      }

      this.$emit("emitLifeEvents", this.life_events);
    },
    industries() {
      if (this.$route.name == "create-facebook-campaign") {
        let industries = this.industries;
        let allindustries = [];
        if (industries) {
          for (let i = 0, len = industries.length; i < len; i++) {
            let industryObj = {};
            industryObj.name = industries[i].name;
            industryObj.id = industries[i].id;
            allindustries.push(industryObj);
          }
        }
        this.$emit("emitIndustries", allindustries);
        return;
      }

      this.$emit("emitIndustries", this.industries);
    },
    income() {
      if (this.$route.name == "create-facebook-campaign") {
        let income = this.income;
        let allincome = [];
        if (income) {
          for (let i = 0, len = income.length; i < len; i++) {
            let incomeObj = {};
            incomeObj.name = income[i].name;
            incomeObj.id = income[i].id;
            allincome.push(incomeObj);
          }
        }
        this.$emit("emitIncome", allincome);
        return;
      }

      this.$emit("emitIncome", this.income);
    },
    user_os() {
      if (this.$route.name == "create-facebook-campaign") {
        let userOS = this.user_os;
        let allOS = [];
        if (userOS) {
          for (let i = 0, len = userOS.length; i < len; i++) {
            allOS.push(userOS[i].platform);
          }
        }
        this.$emit("emitUserOs", allOS);
        return;
      }

      this.$emit("emitUserOs", this.user_os);
      // let user_os = this.user_os;
    },
    selected_job_titles() {
      if (this.$route.name == "create-facebook-campaign") {
        let titles = this.selected_job_titles;
        let alltitles = [];
        if (titles) {
          for (let i = 0, len = titles.length; i < len; i++) {
            let titleObj = {};
            titleObj.name = titles[i].name;
            titleObj.id = titles[i].id;
            alltitles.push(titleObj);
          }
        }
        this.$emit("emitSelectedJobTitles", alltitles);
        return;
      }

      this.$emit("emitSelectedJobTitles", this.selected_job_titles);
    },
    facebook_single_adset() {
      if (this.facebook_single_adset) {
        this.selected_location =
          "location_types" in this.facebook_single_adset.targeting.geo_locations
            ? this.facebook_single_adset.targeting.geo_locations.location_types
                .map((data) =>
                  this.location_options.filter(
                    (location) => location.value == data
                  )
                )
                .map((y) => y[0])
            : null;
        this.countries = this.facebook_single_adset.targeting.geo_locations
          ? this.facebook_single_adset.targeting.geo_locations.countries
          : null;
        this.selected_region = this.facebook_single_adset.targeting
          .geo_locations
          ? this.facebook_single_adset.targeting.geo_locations.regions
          : "";
        this.selected_cities = this.facebook_single_adset.targeting
          .geo_locations
          ? this.facebook_single_adset.targeting.geo_locations.cities
          : "";
        this.selected_zips = this.facebook_single_adset.targeting.geo_locations
          ? this.facebook_single_adset.targeting.geo_locations.zips
          : "";
        this.selected_major = this.facebook_single_adset.targeting
          .education_majors
          ? this.facebook_single_adset.targeting.education_majors
          : "";
        this.age_max = this.facebook_single_adset.targeting.age_max
          ? this.facebook_single_adset.targeting.age_max
          : "";
        this.age_min = this.facebook_single_adset.targeting.age_min
          ? this.facebook_single_adset.targeting.age_min
          : "";
        this.selected_interests = this.facebook_single_adset.targeting
          .flexible_spec
          ? this.facebook_single_adset.targeting.flexible_spec[0].interests
          : "";
        this.behaviors = this.facebook_single_adset.targeting.flexible_spec
          ? this.facebook_single_adset.targeting.flexible_spec[0].behaviors
          : "";
        this.custom_audience =
          this.facebook_single_adset.targeting.custom_audiences;
        this.selected_job_titles =
          this.facebook_single_adset.targeting.work_positions;
        this.life_events = this.facebook_single_adset.targeting.life_events;
        this.industries = this.facebook_single_adset.targeting.industries;
        this.income = this.facebook_single_adset.targeting.income;
        this.user_os = this.facebook_single_adset.targeting.user_os;
        this.selected_language = this.facebook_single_adset.targeting.locales;
      }
    },
  },
  beforeDestroy() {
    this.valid = true;
    this.custom_audience;
    this.age_min = "";
    this.age_max = "";
    this.selected_location = null;
    this.countries = null;
    this.regions = "";
    this.selected_region = [];
    this.city = "";
    this.selected_cities = [];
    this.zip = "";
    this.selected_zips = [];
    this.gender = null;
    this.selected_interests = "";
    this.behaviors = "";
    this.major = "";
    this.selected_major = "";
    this.job_title = "";
    this.selected_job_titles = [];
    this.life_events = [];
    this.industries = [];
    this.income = [];
    this.user_os = [];
    this.selected_language = [];
  },
};
</script>
