<template>
  <v-expansion-panel>
    <v-expansion-panel-header disable-icon-rotate>
      <v-row no-gutters>
        <v-col>
          <v-icon class="mr-1">mdi-format-list-bulleted</v-icon>
          <v-icon x-large>mdi-plus-box</v-icon>
          <span class="text-caption ml-1">Enter headline, description & URL</span>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-icon small> mdi-pencil </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-form v-model="valid" lazy-validation ref="form" class="campaign-form">
        <v-row justify="space-around" no-gutters>
          <v-col>
            <v-row class="pa-5" v-if="selected_hash === ''">
              <v-col display="true" v-for="(image, i) in ad_images" :key="i">
                <v-img v-if="ad_images.length" :lazy-src="image.url" max-height="130" max-width="250" :src="image.url">
                </v-img>
                <v-btn small class="mt-4" elevation="0" @click="selectAdImage(image.hash, image.url)">Select Image
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="selected_hash != ''">
              <v-img :src="selected_image" :lazy-src="selected_image" max-height="150" max-width="250"></v-img>
            </v-row>
            <h6 class="mb-1 d-flex align-center">
              Name<span class="error--text">*</span>
            </h6>
            <v-text-field label="Product or Service Name" outlined dense v-model="name" class="required"
              :rules="[(v) => !!v || 'Input name to save creative!']"></v-text-field>
            <h6 class="mt-4 mb-1 d-flex align-center">
              Description<span class="error--text">*</span>
            </h6>
            <v-text-field label="Include additional details" outlined dense v-model="description" class="required"
              :rules="[(v) => !!v || 'Include description to save creative!']"></v-text-field>

            <h6 class="mt-4 mb-1">
              Link URL or app store URL attached to the product<span class="error--text">*</span>
            </h6>
            <v-text-field label="http://www.example.com/productorservice" outlined dense v-model="link"
              class="mb-4 required" :rules="[(v) => !!v || 'Include link URL to save creative!']">
            </v-text-field>

            <!-- <router-link to="/" class="text-caption"
            >Build a URL parameter</router-link
          > -->

            <div class="d-flex justify-end">
              <v-btn small class="mt-3 success" @click="create()">
                <v-icon small left>mdi-content-save</v-icon> Save Creative
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  data: () => ({
    ad_headline: "",
    display: false,
    selected_hash: "",
    selected_hashes: [],
    selected_image: "",
    name: "",
    description: "",
    link: "",
    descriptions: [],
    names: [],
    site_url: null,
    valid: true
  }),
  props: {
    ad_images: {
      type: Array,
    },
    progress: {
      type: Number,
    },
    clearCarouselData: Boolean
  },
  methods: {
    selectAdImage(hash, url) {
      this.selected_hash = hash;
      this.selected_hashes.push(this.selected_hash);
      this.selected_image = url;
    },
    async create() {
      await this.$refs.form.validate();
      if(!this.valid) return;

      const body = {
        name: this.name,
        description: this.description,
        image_hash: this.selected_hash,
        link: this.link,
      };
      this.$emit("create", body);
    },
  },
  watch: {
    clearCarouselData() {
      this.name = "";
      this.description = "";
      this.selected_hash = "";
      this.link = "";
    }
  }
};
</script>
