<template>
  <v-row>
    <v-col cols="12" sm="4" md="4">
      <h6 class="overline">Awareness</h6>
      <v-radio-group
        v-model="selectedObjective"
        v-for="(objective, i) in objectives[0].options"
        :key="i"
        :disabled="$route.name == 'update-facebook-campaign'"
        dense
        hide-details
      >
        <v-radio
          :label="objective"
          color="primary"
          :value="objective"
          hide-details
        ></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12" sm="4" md="4">
      <h6 class="overline">Consideration</h6>
      <v-radio-group
        v-model="selectedObjective"
        column
        v-for="(objective, i) in objectives[1].options"
        :key="i"
        dense
        hide-details
        :disabled="$route.name == 'update-facebook-campaign'"
      >
        <v-radio
          :label="objective"
          color="primary"
          :value="objective"
          hide-details="auto"
        ></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12" sm="4" md="4">
      <h6 class="overline">Conversion</h6>
      <v-radio-group
        v-model="selectedObjective"
        column
        v-for="(objective, i) in objectives[2].options"
        :key="i"
        dense
        hide-details
        :disabled="$route.name == 'update-facebook-campaign'"
      >
        <v-radio
          :label="objective"
          color="primary"
          :value="objective"
          class="text-caption"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    selectedObjective: null,
    objectives: [
      {
        category: "awareness",
        options: ["Brand Awareness", "Reach"],
      },
      {
        category: "consideration",
        options: [
          "Traffic",
          "Engagement",
          "App Installs",
          "Video Views",
          "Lead Generation",
          "Messages",
        ],
      },
      {
        category: "conversion",
        options: ["Conversions"],
      },
    ],
  }),
  watch: {
    selectedObjective(newObjective, oldObjective) {
      if (newObjective !== oldObjective) {
        this.$emit("chosenObjective", newObjective.toUpperCase().replaceAll(" ", "_"));
      }
    },
  },
  methods: {
    async setObjective() {
      if (this.facebook_campaign) {
        const splittedWords = await this.facebook_campaign.objective
          .toLowerCase()
          .replaceAll("_", " ")
          .split(" ");

        this.selectedObjective = splittedWords
          .map((word) => word.charAt(0).toUpperCase() + word.substr(1))
          .join(" ");
      }
    },
  },
  props: {
    facebook_campaign: {
      type: Object,
    },
  },
  async mounted() {
    await this.setObjective();
  },
  beforeDestroy() {
    this.selectedObjective = null;
  },
};
</script>
