<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      max-width="1000"
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <h6>Choose or upload image(s) to use</h6>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col class="mt-5">
              <div class="d-flex align-center upload">
                <v-progress-linear
                  color="light-blue"
                  height="10"
                  :value="progress"
                  striped
                  v-if="currentFile"
                ></v-progress-linear>

                <label class="btn btn-default">
                  <input
                    type="file"
                    ref="file"
                    prepend-icon="mdi-camera"
                    @change="handleImage"
                    accept="image/*"
                  />
                </label>
                <v-btn
                  :disabled="!image"
                  @click="uploadImage"
                  class="mt-1 ml-1"
                  small
                  color="warning"
                  >Upload</v-btn
                >
              </div>
            </v-col>
          </v-row>

          <v-row class="pa-5">
            <v-col
              v-for="(image, i) in ad_images"
              :key="i"
              :class="{ selected: selected_hashes[i] }"
            >
              <v-img
                class="lighten-2 selected"
                :class="{ custom: selected === image.hash }"
                v-if="ad_images.length"
                :src="image.url"
                :lazy-src="image.url"
                height="200"
                width="200"
                @click="selectSingleAdImage(image.hash)"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>

          <v-row v-if="selected_hash" class="my-5" justify="center">
            <v-col cols="5">
              <h5 class="mb-4">Image Link</h5>
              <v-text-field
                dense
                outlined
                label="Change image link or use the default"
                v-model="image_link"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$emit('closeDialog', false)" text color="primary">
            Cancel
          </v-btn>
          <v-btn v-if="selected_hash" @click="postAdCreative" color="primary">
            Next
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    currentFile: undefined,
    message: "",
    fileInfos: [],
    selected_hashes: [],
    selected_hash: "",
    selected: "",
    img_id: null,
    image: "",
    image_link: "www.mediapal.net",
  }),
  props: {
    ad_images: {
      type: Array,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
    },
  },
  methods: {
    // selectAdImage({ image, i }) {
    //   console.log("Huhuuu", image, i);
    //   if (
    //     this.selected_hashes.some(
    //       (selected_hash) => selected_hash === image.hash
    //     )
    //   ) {
    //     const indexOfObject = this.selected_hashes.findIndex((object) => {
    //       return object === image.hash;
    //     });

    //     console.log(indexOfObject);
    //     this.selected_hashes.splice(indexOfObject, 1);
    //     console.log("Array yangu2", this.selected_hashes);
    //     return;
    //   }

    //   this.img_id = i;

    //   this.selected_hashes.push(image.hash);
    // },
    selectSingleAdImage(hash) {
      this.selected_hash = hash;
      this.selected = hash;
    },
    postAdCreative() {
      this.$emit("postAdCreative", {
        hash: this.selected_hash,
        dialog: false,
        image_link: this.image_link,
      });
    },
    async handleImage(e) {
      let file = e.target.files[0];
      await this.getBase64(file).then((data) => (this.image = data));
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
        };
        reader.onerror = (error) => reject(error);
      });
    },

    uploadImage() {
      this.$emit(
        "uploadImage",
        this.image);
      this.image = "";
    },
  },
};
</script>

<style scoped>
.selected {
  cursor: pointer;
}
.custom {
  border: 10px solid;
  cursor: pointer;
  border-color: green !important;
}
.upload {
  border: 1px solid gray;
  padding:10px;
  border-radius: 10px;
}
</style>
