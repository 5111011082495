<template>
  <v-form lazy-validation ref="form" v-model="valid" class="campaign-form">
    <v-row>
      <v-col cols="12">
        <h5>Ad creative</h5>
        <span class="text-caption">Select the media, text and destination for your ad. You can also
          customise your media and text for each placement.</span>
      </v-col>
      <v-expansion-panels class="mt-3" focusable tile flat v-if="ad_format == 'carousel'" v-model="panel">
        <v-col cols="12">
          <h6 class="mt-4 mb-1">Headline<span class="error--text">*</span></h6>
          <v-text-field class="required" label="Write a short ad headline" outlined dense v-model="ad_headline" :rules="[(v) => !!v || 'Please include headline to submit carousel creatives!']"></v-text-field>
        </v-col>
        <carousel-expansion-panel @create="createCarouselAd($event)" :ad_images="ad_images" :clearCarouselData="clearCarouselData" />
        <carousel-expansion-panel @create="createCarouselAd($event)" :ad_images="ad_images" :clearCarouselData="clearCarouselData"  />
        <carousel-expansion-panel @create="createCarouselAd($event)" :ad_images="ad_images" :clearCarouselData="clearCarouselData"  />
        <carousel-expansion-panel @create="createCarouselAd($event)" :ad_images="ad_images" :clearCarouselData="clearCarouselData"  v-if="fourth_carousel" />
        <carousel-expansion-panel @create="createCarouselAd($event)" :ad_images="ad_images" :clearCarouselData="clearCarouselData"  v-if="fifth_carousel" />
        <carousel-expansion-panel @create="createCarouselAd($event)" :ad_images="ad_images" :clearCarouselData="clearCarouselData"  v-if="sixth_carousel" />
        <carousel-expansion-panel @create="createCarouselAd($event)" :ad_images="ad_images" :clearCarouselData="clearCarouselData"  v-if="seventh_carousel" />
        <carousel-expansion-panel @create="createCarouselAd($event)" :ad_images="ad_images" :clearCarouselData="clearCarouselData"  v-if="eigth_carousel" />
        <carousel-expansion-panel @create="createCarouselAd($event)" :ad_images="ad_images" :clearCarouselData="clearCarouselData"  v-if="nineth_carousel" />
        <carousel-expansion-panel @create="createCarouselAd($event)" :ad_images="ad_images" :clearCarouselData="clearCarouselData"  v-if="tenth_carousel" />
      </v-expansion-panels>

      <v-col v-if="more_carousel < 7 && ad_format == 'carousel'" cols="12">
        <v-btn class="float-right mt-3" @click="moreCarousel">
          <v-icon small left>mdi-plus</v-icon> Add More Carousels
        </v-btn>
      </v-col>

      <v-col v-if="ad_format == 'carousel'">
        <v-text-field label="URL of a link to see more" outlined dense v-model="site_url" class="mb-4 required" :rules="[(v) => !!v || 'Please include a URL to see more!']">Link to See
          more</v-text-field>
        <v-btn class="float-left mt-3 success" @click="postCarousel" v-if="carousel_creatives.length >= 2">Submit
          Carousel</v-btn>
        <v-btn class="float-right mt-3 error" v-if="carousel_creatives.length >= 1" @click="clearCarousels">
          <v-icon small left>mdi-delete</v-icon> Clear Carousel
        </v-btn>
      </v-col>

      <v-col cols="12" v-if="ad_format == 'carousel'">
        <h5 class="my-3">Edit placement</h5>
        <v-select label="Select a placement to edit" disabled outlined dense></v-select>
      </v-col>

      <v-col cols="12" v-if="ad_format == 'carousel'">
        <v-list flat>
          <v-list-item-group>
            <v-list-item three-line>
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>Automatically show the best-performing cards
                    first</v-list-item-title>
                  <v-list-item-subtitle>On Facebook News Feed, Instagram feed and Instagram
                    Stories, the carousel card that performs best will be shown
                    first. For all other placements, cards will appear in the
                    order in which you arrange them.</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>

            <v-list-item>
              <template v-slot:default="{ active }" class="d-flex align-center">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    Add a card at the end with your Page profile
                    picture</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>

      </v-col>

      <v-col cols="12" class="mb-5" v-if="ad_format !== 'video'">
        <h5 class="my-3">Primary text<span class="error--text">*</span></h5>
        <v-text-field outlined label="What's your ad about? Try up to five options" v-model="message" required
          :rules="[(v) => !!v || 'Fill this field to proceed!']" class="required"></v-text-field>
      </v-col>

      <v-col class="mb-3" cols="12" v-if="ad_format == 'video'"
        ><video-upload
          @uploadVideo="uploadVideo($event)"
          @postVideoCreative="postVideoCreative($event)"
      /></v-col>

      <v-col cols="12" v-if="ad_format == 'single'">
        <media-placements @postAdCreative="postAdCreative($event)" @uploadImage="uploadImage($event)"
          :ad_images="ad_images" :progress="progress" />
      </v-col>

      <v-col cols="12" v-if="ad_format == 'single' || ad_format == 'carousel'">
        <v-list three-line class="mt-7">
          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="add_destination"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Add a destination</v-list-item-title>
              <v-list-item-subtitle>If you add a destination, you can send people immediately after
                they tap or click your ad to a website or a full-screen
                experience. If you don't, they'll be sent to your Facebook Page
                or Instgram account.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>

      <transition name="slide-x-transition">
        <v-col cols="12" v-if="
          (ad_format == 'single' && add_destination) ||
          (ad_format == 'carousel' && add_destination) ||
          ad_format == 'collection'
        ">
          <h5 class="my-3">Headline<span class="error--text">*</span></h5>
          <v-text-field outlined dense label="Write up to five short headlines" class="required" :rules="[(v) => !!v || 'Please input headline!']"></v-text-field>
        </v-col>
      </transition>

      <v-col cols="12" v-if="ad_format == 'single'">
        <h5 class="my-3">Description<span class="error--text">*</span></h5>
        <v-text-field class="required" outlined label="Why should people visit your website? Try up to five options" :rules="[(v) => !!v || 'Include a description to continue!']"></v-text-field>
      </v-col>

      <v-col cols="12" v-if="ad_format == 'single' || ad_format == 'carousel'">
        <h5 class="mt-8 mb-3">Call to action</h5>
        <v-select v-model="action" :items="actions" outlined dense item-text="title" item-value="value">
          <template v-slot:item="{ active, item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs" :default="{ active }" two-line>
              <v-list-item-action>
                <v-radio-group v-model="action">
                  <v-radio :value="item.value"></v-radio>
                </v-radio-group>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import CarouselExpansionPanel from "./CarouselExpansionPanel.vue";
import MediaPlacements from "./MediaPlacements.vue";
import VideoUpload from "./VideoUpload.vue";

export default {
  data: () => ({
    actions: [
      { title: "Learn More", value: "learn_more" },
      { title: "No button", value: "no_button" },
      { title: "Shop Now", value: "shop_now" },
      { title: "Sign Up", value: "sign_up" },
      { title: "Subscribe", value: "subscribe" },
      { title: "Watch More", value: "watch_more" },
      { title: "Send WhatsApp Message", value: "send_whatsapp_message" },
      { title: "Listen Now", value: "listen_now" },
      { title: "Apply Now", value: "apply_now" },
      { title: "Book Now", value: "book_now" },
      { title: "Contact Us", value: "contact_us" },
      { title: "Download", value: "download" },
      { title: "Get Quote", value: "get_quote" },
      { title: "Get Showtimes", value: "get_showtimes" },
      { title: "Send Message", value: "send_message" },
      { title: "Request Time", value: "request_time" },
      { title: "See Menu", value: "see_menu" },
    ],
    action: "sign_up",
    add_destination: false,
    message: "",
    caption: "",
    valid: true,
    facebook_positions: null,
    carousel_creatives: [],
    campaign_objective: "",
    site_url: null,
    creative_data: "",
    ad_headline: "",
    panel: null,
    more_carousel: 0,
    fourth_carousel: false,
    fifth_carousel: false,
    sixth_carousel: false,
    seventh_carousel: false,
    eigth_carousel: false,
    nineth_carousel: false,
    tenth_carousel: false,
    clearCarouselData: false
  }),
  props: {
    ad_format: {
      type: String,
    },
    ad_images: {
      type: Array,
    },
    progress: {
      type: Number,
    },
    isValid: {
      type: Boolean,
    },
    facebook_adsets: {
      type: Array,
    },
    selected_adset_id: {
      type: String
    },
    selected_campaign: {
      type: Object
    },
    facebook_page_id: {
      type: String
    }
  },
  components: {
    CarouselExpansionPanel,
    MediaPlacements,
    VideoUpload,
  },
  watch: {
    add_destination() {
      this.$emit("addDestination", this.add_destination);
    },
    isValid() {
      this.$refs.form.validate();
      this.$emit("setValidity", this.valid);
    },
    ad_headline() {
      this.$emit("emitCarouselHeadline", this.ad_headline);
    },
  },
  methods: {
    postAdCreative({ hash, image_link }) {
      let data = {
        message: this.message,
        hash: hash,
        image_link: image_link,
        action: this.action.toUpperCase(),
      };
      this.$emit("postAdCreative", data);
    },
    uploadImage(bytes) {
      this.$emit("uploadImage", bytes);
    },
    createCarouselAd(body) {
      this.panel = null;
      this.carousel_creatives.push(body);
    },
    postCarousel() {
      const link_data = {
        child_attachments: this.carousel_creatives,
        link: this.site_url
      }
      this.$emit("emitCarouselData", link_data);
    },
    moreCarousel() {
      this.more_carousel++;
      if (this.more_carousel == 1) this.fourth_carousel = true;
      if (this.more_carousel == 2) this.fifth_carousel = true;
      if (this.more_carousel == 3) this.sixth_carousel = true;
      if (this.more_carousel == 4) this.seventh_carousel = true;
      if (this.more_carousel == 5) this.eigth_carousel = true;
      if (this.more_carousel == 6) this.nineth_carousel = true;
      if (this.more_carousel == 7) this.tenth_carousel = true;
    },
    clearCarousels() {
      this.clearCarouselData = !this.clearCarouselData;
      this.carousel_creatives = [];
      this.panel = null;
      this.more_carousel = 0;
      this.fourth_carousel = false;
      this.fifth_carousel = false;
      this.sixth_carousel = false;
      this.seventh_carousel = false;
      this.eigth_carousel = false;
      this.nineth_carousel = false;
      this.tenth_carousel = false;
    },
    uploadVideo(video_data) {
      this.$emit("uploadVideo", video_data);
    },
    postVideoCreative(video_data) {
      this.$emit("postVideoCreative", video_data);
    },
  },
  beforeDestroy() {
    this.valid = true;
    this.panel = null;
    this.ad_headline = "";
    this.site_url = null;
    this.message = "";
    this.add_destination = false;
    this.action = "sign_up";
  }
};
</script>