<template>
  <v-col>
    <h3 class="mb-3">Campaign details</h3>
    <div class="d-flex">
      <div class="mr-10">
        <h6 class="overline">Buying type</h6>
        <p class="font-weight-bold">Auction</p>
      </div>
      <div>
        <h6 class="overline">Campaign objective</h6>
        <p class="font-weight-bold text-capitalize">
          {{ campaignGoal.replaceAll("_", " ").toLowerCase() }}
        </p>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    campaignGoal: {
      type: String,
    },
  },
};
</script>
