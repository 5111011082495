<template>
  <v-form v-model="valid" class="campaign-form" ref="form" lazy-validation>
    <v-row>
      <v-col cols="7">
        <general-card class="mb-4">
          <v-card-text>
            <v-row v-if="this.$route.name == 'create-facebook-campaign'">
              <v-col>
                <h4 class="mb-2">
                  Choose campaign<span class="error--text">*</span>
                </h4>
                <v-select
                  :items="facebook_campaigns"
                  outlined
                  dense
                  item-text="name"
                  return-object
                  v-model="campaign"
                  label="Select campaign"
                  required
                  :rules="[(v) => !!v || 'Campaign is required!']"
                  hide-details="auto"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h4 class="mb-2">
                  Ad set name<span class="error--text">*</span>
                </h4>
                <v-text-field
                  v-model="name"
                  required
                  :rules="nameRules"
                  :items="name"
                  single-line
                  label="Ad set name"
                  outlined
                  hide-details="auto"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </general-card>

        <general-card
          class="mb-4"
          v-if="this.$route.name == 'create-facebook-campaign'"
        >
          <dynamic-creative @emitDynamicCreative="setDynamicCreative($event)" />
        </general-card>

        <general-card class="mb-5">
          <transition name="slide-x-transition">
            <budget-schedule
              @setSpend="setSpend($event)"
              @setValidity="setValidity($event)"
              :facebook_single_adset="facebook_single_adset"
              v-if="!hideBudgetSection"
              :isValid="isValid"
            />
            <!-- && facebook_single_adset && facebook_single_adset.daily_budget != 0 -->
          </transition>
          <schedule
            @emitstartdate="postStartDate($event)"
            @emitenddate="postEndDate($event)"
            @emitstarttime="postStartTime($event)"
            @emitendtime="postEndTime($event)"
            :facebook_single_adset="facebook_single_adset"
          />
        </general-card>

        <audience
          class="mb-3"
          @emitValidity="setValidity($event)"
          @setCountries="setCountries($event)"
          @emitCustomAudience="getCustomAudience($event)"
          @emitZip="getZipInt($event)"
          @emitEmployer="getEmployer($event)"
          @emitCity="getCityName($event)"
          @emitRegions="getMyRegions($event)"
          @emitJobTitles="getJobTitles($event)"
          @emitMajor="getEducationMajors($event)"
          @emitLongitude="getLongitude($event)"
          @emitBehaviors="postBehaviors($event)"
          @emitIncome="postIncome($event)"
          @emitIndustries="postIndustries($event)"
          @emitSelectedRegion="postSelectedRegion($event)"
          @emitSelectedCities="postSelectedCity($event)"
          @emitSelectedZips="postSelectedZips($event)"
          @emitSelectedMajor="postEducationMajor($event)"
          @emitSelectedJobTitles="postSelectedJobTitle($event)"
          @emitLifeEvents="postLifeEvents($event)"
          @emitLanguage="postLanguage($event)"
          @emitUserOs="postUserOS($event)"
          @emitInterests="postInterests($event)"
          @emitMaxAge="postAgeMax($event)"
          @emitMinAge="postMinAge($event)"
          @setGender="postGender($event)"
          @emitSelectedLocations="setSelectedLocations($event)"
          :facebook_campaigns="facebook_campaigns"
          :custom_audiences="custom_audiences"
          :all_countries="all_countries"
          :all_regions="all_regions"
          :all_cities="all_cities"
          :all_majors="all_majors"
          :all_zips="all_zips"
          :all_languages="all_languages"
          :all_incomes="all_incomes"
          :all_user_os="all_user_os"
          :available_interests="available_interests"
          :available_behaviors="available_behaviors"
          :all_life_events="all_life_events"
          :all_industries="all_industries"
          :all_job_titles="all_job_titles"
          :facebook_single_adset="facebook_single_adset"
          :isValid="isValid"
        />

        <placement
          @getSelectedDevicePlatforms="devicePlatforms($event)"
          @postSelectedPublisherPlatforms="publisherPlatforms($event)"
          @emitFacebookPositions="facebookPositions($event)"
          @emitInstagramPositions="instagramPositions($event)"
          @emitAudienceNetworkPlacements="audienceNetworkPositions($event)"
          @emitMessengerPlacements="messengerPlacements($event)"
          v-if="showPlacements"
          :facebook_single_adset="facebook_single_adset"
        />

        <optimisation-delivery
          @setBillingEvent="setBillingEvent($event)"
          @setBidStrategy="setBidStrategy($event)"
          @setBillingAndBid="setBillingAndBid($event)"
          :facebook_single_adset="facebook_single_adset"
        />
      </v-col>
      <v-col cols="5">
        <general-card v-if="this.$route.name == 'update-facebook-adset'">
          <v-card-actions class="pa-4">
            <v-btn depressed color="primary" @click="update">
              Update Adset
            </v-btn>
          </v-card-actions>
        </general-card>
        <general-card v-if="this.$route.name == 'create-facebook-campaign'">
          <estimated-daily-results />
          <v-card-actions class="pa-4">
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  depressed
                  color="primary"
                  v-if="isFacebook"
                  @click.stop="create"
                >
                  Next
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  to="/tradedesk/create-campaign?tab=visuals"
                  v-else
                >
                  Next
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </general-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import GeneralCard from "../../Layouts/Cards/GeneralCard.vue";
import DynamicCreative from "./DynamicCreative.vue";
import BudgetSchedule from "./BudgetSchedule.vue";
import Schedule from "./Schedule.vue";
import Audience from "./Audience.vue";
import Placement from "./Placement.vue";
import OptimisationDelivery from "./OptimisationDelivery.vue";
import EstimatedDailyResults from "./EstimatedDailyResults.vue";

export default {
  data: () => ({
    nameRules: [
      (v) => !!v || "Name is required!",
      (v) => v.length <= 50 || "Name must be less than 50 characters!",
    ],
    showPlacements: false,
    displayMap: false,
    endDate: "",
    startDate: "",
    start_time: "",
    end_time: "",
    from: 18,
    to: 65,
    name: "",
    adsetId: "",
    gender: null,
    bid_strategy: null,
    billing_event: null,
    campaign: null,
    user_interests: [],
    daily_budget: "",
    countries: [],
    regions: [],
    region: [],
    job_title: null,
    city: null,
    cities: [],
    major: [],
    zips: [],
    languages: [],
    locales: [],
    selected_job_titles: [],
    work_positions: [],
    zip: [],
    ageRange: [],
    text: "all",
    eCPM: null,
    row: null,
    budget: null,
    impressions: null,
    selectedDate: null,
    fCap: null,
    date: new Date().getTimezoneOffset(),
    menu: false,
    isFacebook: false,
    custom_audience: null,
    behaviors: [],
    interests: [],
    life_events: [],
    industries: [],
    income: [],
    user_os: [],
    education_major: [],
    valid: true,
    device_platforms: [],
    publisher_platforms: [],
    facebook_positions: [],
    instagram_positions: [],
    audience_network_positions: [],
    messenger_positions: [],
    isHidden: false,
    default_time_zone: ":00+0000",
    hideBudgetSection: false,
    is_dynamic_creative: false,
    isValid: false,
    location_types: [],
  }),
  components: {
    GeneralCard,
    DynamicCreative,
    BudgetSchedule,
    Schedule,
    Audience,
    Placement,
    OptimisationDelivery,
    EstimatedDailyResults,
  },
  props: {
    api: {
      type: Object,
      default: null,
    },
    facebook_campaigns: {
      type: Array,
    },
    custom_audiences: {
      type: Array,
    },
    all_countries: {
      type: Array,
    },
    all_regions: {
      type: Array,
    },
    all_cities: {
      type: Array,
    },
    all_zips: {
      type: Array,
    },
    all_languages: {
      type: Array,
    },
    available_interests: {
      type: Array,
    },
    available_behaviors: {
      type: Array,
    },
    all_life_events: {
      type: Array,
    },
    all_industries: {
      type: Array,
    },
    all_incomes: {
      type: Array,
    },
    all_majors: {
      type: Array,
    },
    all_job_titles: {
      type: Array,
    },
    all_user_os: {
      type: Array,
    },
    facebook_single_adset: {
      type: Object,
    },
    latest_campaign: {
      type: Object,
    },
  },
  mounted() {
    this.$route.name == "create-facebook-campaign"
      ? (this.isFacebook = true)
      : null;

    if (this.latest_campaign) {
      this.campaign = this.latest_campaign;
    }

    //This logic needs to be refactored because after creating a campaign, it takes like 20s to update the object

    //Already called fetchCampaigns on creation hook
    // this.$emit("fetchCampaigns");
  },
  watch: {
    facebook_single_adset() {
      this.name = this.facebook_single_adset
        ? this.facebook_single_adset.name
        : "";
    },
    latest_campaign() {
      this.campaign = this.latest_campaign;
    },
    campaign() {
      if (this.campaign) {
        this.hideBudgetSection = this.campaign.daily_budget ? true : false;
      }
    },
    daily_budget() {
      this.budget = parseInt(this.daily_budget) * 100;
    },
  },
  methods: {
    setValidity(validity) {
      this.valid = validity;
    },
    setSelectedLocations(location_types) {
      this.location_types = location_types;
    },
    setDynamicCreative(is_dynamic_creative) {
      this.is_dynamic_creative = is_dynamic_creative;
    },
    getZipInt(zip) {
      this.$emit("emitZip", zip);
      this.zip = zip;
    },
    postGender(gender) {
      this.gender = gender;
    },
    getCityName(city) {
      this.$emit("emitCity", city);
      this.city = city;
    },
    getLanguage(selected_language) {
      this.$emit("emitSelectedLanguage", selected_language);
      this.locales = selected_language;
    },
    getMyRegions(regions) {
      this.$emit("emitRegions", regions);
      this.regions = regions;
    },
    getEducationMajors(major) {
      this.$emit("emitMajor", major);
      this.major = major;
    },
    getJobTitles(job_title) {
      this.$emit("emitJobTitles", job_title);
      this.job_title = job_title;
    },
    getLatitude(latitude) {
      this.$emit("emitLeLatitude", latitude);
    },
    getLongitude(longitude) {
      this.$emit("emitLeLongitude", longitude);
    },
    setBidStrategy(strategy) {
      this.bid_strategy = strategy;
    },
    setBillingEvent(event) {
      this.billing_event = event;
    },
    setBillingAndBid({ billing_event, bid_strategy }) {
      this.billing_event = billing_event.toUpperCase();
      this.bid_strategy = bid_strategy.toUpperCase();
    },
    setSpend(spend) {
      this.daily_budget = spend;
    },
    setCountries(countries) {
      this.countries = countries;
    },
    setRegions(regions) {
      this.regions = regions;
    },
    getCustomAudience(custom_audience) {
      this.custom_audience = custom_audience;
    },
    postMinAge(age_min) {
      this.from = age_min;
    },
    postAgeMax(age_max) {
      this.to = age_max;
    },
    postBehaviors(behaviors) {
      this.behaviors = behaviors;
    },
    postStartDate(start_date) {
      this.startDate = start_date;
    },
    postEndDate(end_date) {
      this.endDate = end_date;
    },
    postStartTime(start_time) {
      this.start_time = start_time;
    },
    postEndTime(end_time) {
      this.end_time = this.endDate + "T" + end_time + this.default_time_zone;
    },
    postLanguage(language) {
      this.locales = language;
    },
    postLifeEvents(life_events) {
      this.life_events = life_events;
    },
    postIncome(income) {
      this.income = income;
    },
    postUserOS(user_os) {
      this.user_os = user_os;
    },
    getAdsetName(name) {
      this.name = name;
    },
    getCampaign(campaign_id) {
      this.campaign_id = campaign_id;
    },
    postInterests(interests) {
      this.interests = interests;
    },
    postSelectedRegion(selected_region) {
      this.region = selected_region;
    },
    postSelectedCity(selected_cities) {
      this.cities = selected_cities;
    },
    postSelectedZips(selected_zips) {
      this.zip = selected_zips;
    },
    postIndustries(industries) {
      this.industries = industries;
    },
    postEducationMajor(selected_major) {
      this.education_major = selected_major;
    },
    postSelectedJobTitle(selected_job_titles) {
      this.work_positions = selected_job_titles;
    },
    devicePlatforms(devices) {
      this.device_platforms = devices;
    },
    publisherPlatforms(publishers) {
      this.publisher_platforms = publishers;
    },
    facebookPositions(positions) {
      this.facebook_positions = positions;
    },
    instagramPositions(positions) {
      this.instagram_positions = positions;
    },
    audienceNetworkPositions(positions) {
      this.audience_network_positions = positions;
    },
    messengerPlacements(positions) {
      this.messenger_positions = positions;
    },
    async validate() {
      this.isValid = !this.isValid;
      await this.$refs.form.validate();
    },
    async create() {
      await this.validate();
      if (!this.valid) return;

      const body = {
        name: this.name,
        campaign_id: this.campaign ? this.campaign.id : null,
        billing_event: this.billing_event,
        bid_strategy: this.bid_strategy,
        start_time:
          this.startDate + "T" + this.start_time + this.default_time_zone,
        end_time: this.end_time,
        daily_budget: this.budget,
        targeting: {
          age_min: this.from,
          age_max: this.to,
          custom_audiences: this.custom_audience,
          behaviors: this.behaviors,
          interests: this.interests,
          genders: this.gender,
          education_majors: this.education_major,
          work_positions: this.work_positions,
          locales: this.locales,
          device_platforms: this.device_platforms,
          publisher_platforms: this.publisher_platforms,
          facebook_positions: this.facebook_positions,
          instagram_positions: this.instagram_positions,
          audience_network_positions: this.audience_network_positions,
          messenger_positions: this.messenger_positions,
          geo_locations: {
            countries: this.countries,
            regions: this.region,
            cities: this.cities,
            zips: this.zips,
            location_types: this.location_types,
          },
          life_events: this.life_events,
          industries: this.industries,
          income: this.income,
          user_os: this.user_os,
        },
      };

      let sanitized_data = await this.sanitizedData(body);
      this.$emit("create", sanitized_data);
    },
    async sanitizedData(data) {
      // Remove daily_buget key || Convert daily_budget into cents
      !data.daily_budget ? delete data.daily_budget : null;

      // Remove end_time key
      !data.end_time ? delete data.end_time : null;

      if (data.targeting) {
        // Remove audience_network_positions key
        if ("audience_network_positions" in data.targeting)
          !data.targeting.audience_network_positions.length
            ? delete data.targeting.audience_network_positions
            : null;

        // Remove behaviors key
        if ("behaviors" in data.targeting)
          !data.targeting.behaviors.length
            ? delete data.targeting.behaviors
            : null;

        // Remove custom_audiences key
        !data.targeting.custom_audiences
          ? delete data.targeting.custom_audiences
          : null;

        // Remove device_platforms key
        if ("device_platforms" in data.targeting)
          !data.targeting.device_platforms.length
            ? delete data.targeting.device_platforms
            : null;

        // Remove education_majors key
        if ("education_majors" in data.targeting)
          !data.targeting.education_majors.length
            ? delete data.targeting.education_majors
            : null;

        // Remove facebook_positions key
        if ("facebook_positions" in data.targeting)
          !data.targeting.facebook_positions.length
            ? delete data.targeting.facebook_positions
            : null;

        // Remove genders key
        !data.targeting.genders ? delete data.targeting.genders : null;

        // Remove countries key
        if ("countries" in data.targeting.geo_locations)
          !data.targeting.geo_locations.countries.length
            ? delete data.targeting.geo_locations.countries
            : null;

        // Remove cities key
        if (
          "cities" in data.targeting.geo_locations &&
          data.targeting.geo_locations.cities !== undefined
        )
          !data.targeting.geo_locations.cities.length
            ? delete data.targeting.geo_locations.cities
            : null;
        data.targeting.geo_locations.cities == undefined
          ? delete data.targeting.geo_locations.cities
          : null;

        // Remove regions key
        if (
          "regions" in data.targeting.geo_locations &&
          data.targeting.geo_locations.regions !== undefined
        )
          !data.targeting.geo_locations.regions.length
            ? delete data.targeting.geo_locations.regions
            : null;
        data.targeting.geo_locations.regions == undefined
          ? delete data.targeting.geo_locations.regions
          : null;

        // Remove zips key
        if ("zips" in data.targeting.geo_locations)
          !data.targeting.geo_locations.zips.length
            ? delete data.targeting.geo_locations.zips
            : null;

        // Remove geo_locations key
        !data.targeting.geo_locations
          ? delete data.targeting.geo_locations
          : null;

        // Remove income key
        if ("income" in data.targeting && data.targeting.income !== undefined)
          !data.targeting.income.length ? delete data.targeting.income : null;
        data.targeting.income == undefined
          ? delete data.targeting.income
          : null;

        // Remove industries key
        if (
          "industries" in data.targeting &&
          data.targeting.industries !== undefined
        )
          !data.targeting.industries.length
            ? delete data.targeting.industries
            : null;
        data.targeting.industries == undefined
          ? delete data.targeting.industries
          : null;

        // Remove instagram_positions key
        if ("instagram_positions" in data.targeting)
          !data.targeting.instagram_positions.length
            ? delete data.targeting.instagram_positions
            : null;

        // Remove interests key
        if ("interests" in data.targeting)
          !data.targeting.interests.length
            ? delete data.targeting.interests
            : null;

        // Remove life_events key
        if (
          "life_events" in data.targeting &&
          data.targeting.life_events !== undefined
        )
          !data.targeting.life_events.length
            ? delete data.targeting.life_events
            : null;
        data.targeting.life_events == undefined
          ? delete data.targeting.life_events
          : null;

        // Remove locales key
        if ("locales" in data.targeting && data.targeting.locales !== undefined)
          !data.targeting.locales.length ? delete data.targeting.locales : null;
        data.targeting.locales == undefined
          ? delete data.targeting.locales
          : null;

        // Remove messenger_positions key
        if ("messenger_positions" in data.targeting)
          !data.targeting.messenger_positions.length
            ? delete data.targeting.messenger_positions
            : null;

        // Remove publisher_platforms key
        if ("publisher_platforms" in data.targeting)
          !data.targeting.publisher_platforms.length
            ? delete data.targeting.publisher_platforms
            : null;

        // Remove work_positions key
        if (
          "work_positions" in data.targeting &&
          data.targeting.work_positions !== undefined
        )
          !data.targeting.work_positions.length
            ? delete data.targeting.work_positions
            : null;
        data.targeting.work_positions == undefined
          ? delete data.targeting.work_positions
          : null;

        // Remove user_os key
        if ("user_os" in data.targeting && data.targeting.user_os !== undefined)
          !data.targeting.user_os.length ? delete data.targeting.user_os : null;
        data.targeting.user_os == undefined
          ? delete data.targeting.user_os
          : null;

        // Remove location_types key
        if (
          "location_types" in data.targeting.geo_locations &&
          data.targeting.geo_locations.location_types !== undefined
        )
          !data.targeting.geo_locations.location_types.length
            ? delete data.targeting.geo_locations.location_types
            : null;
        !data.targeting.geo_locations.location_types
          ? delete data.targeting.geo_locations.location_types
          : null;
      }
      return data;
    },
    async update() {
      await this.validate();
      if (!this.valid) return;

      const body = {
        name: this.name,
        billing_event: this.billing_event,
        bid_strategy: this.bid_strategy,
        daily_budget: this.budget,
        end_time: this.end_time,
        targeting: {
          age_min: this.from,
          age_max: this.to,
          custom_audiences: this.custom_audience,
          behaviors: this.behaviors,
          interests: this.interests,
          genders: this.gender,
          education_majors: this.education_major,
          work_positions: this.work_positions,
          locales: this.locales,
          device_platforms: this.device_platforms,
          publisher_platforms: this.publisher_platforms,
          facebook_positions: this.facebook_positions,
          instagram_positions: this.instagram_positions,
          audience_network_positions: this.audience_network_positions,
          messenger_positions: this.messenger_positions,
          geo_locations: {
            countries: this.countries,
            regions: this.region,
            cities: this.cities,
            zips: this.zips,
            location_types: this.location_types,
          },
          life_events: this.life_events,
          industries: this.industries,
          income: this.income,
          user_os: this.user_os,
        },
      };

      let sanitized_data = await this.sanitizedData(body);
      this.$emit("update", sanitized_data);
    },
  },
  beforeDestroy() {
    this.valid = true;
    this.campaign = null;
    this.name = "";
  },
};
</script>

<style scoped>
#adset .theme--light.v-divider {
  border-color: rgba(235, 235, 235, 0.3);
}

#custom .v-btn:not(.v-btn--round).v-size--small {
  width: 93%;
}

.text-blue {
  color: #3371e2 !important;
}

#calendar .v-input__icon {
  height: 24px !important;
  width: 0;
}

.no-space label {
  font-size: 0.7rem !important;
}

.no-space .v-icon.v-icon--dense {
  font-size: 0.7rem !important;
  color: rgba(112, 112, 112, 0.5) !important;
}

.row + .row {
  margin-top: 0;
}

.v-btn {
  text-transform: inherit !important;
}

#custom .v-item--active {
  background: linear-gradient(to right, #3371e2, #64a7f4);
  color: #fff;
  border: none;
}

.adset-form .v-input__control {
  flex-wrap: nowrap;
}
</style>
