<template>
  <general-card>
    <v-card-title class="py-2">
      <h5>Optimisation and Delivery</h5>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col>
          <h6 class="overline">Optimisation for ad delivery</h6>
          <h5>Add recall lift</h5>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h6 class="overline">Cost control</h6>
          <h5>
            Meta will aim to get the most ad recall lift and spend your entire budget.
          </h5>
        </v-col>
      </v-row>
    </v-card-text>

    <v-expansion-panels accordion tile flat class="mb-2">
      <v-expansion-panel>
        <v-expansion-panel-header> More options </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">
              <h6 class="mb-1">When you are charged</h6>
              <div class="d-flex justify-space-between align-center">
                <!-- <span v-if="!edit_billing_event" class="text-caption text-capitalize">{{
                  billing_event ? billing_event.replaceAll("_", " ") : null
                }}</span> -->
                <v-select
                  outlined
                  dense
                  :items="billing_events"
                  v-model="billing_event"
                  hide-details="auto"
                ></v-select>
                <!-- <span
                  class="text-body-2 ml-2 pointer text--disabled"
                  @click="edit_billing_event = !edit_billing_event"
                >
                  <v-icon small disabled>mdi-pencil-outline</v-icon> Edit
                </span> -->
              </div>
            </v-col>
            <v-col>
              <h6 class="mb-1">Bid Strategy</h6>
              <div class="d-flex justify-space-between align-center">
                <!-- <span v-if="!edit_bid_strategy" class="text-caption text-capitalize">{{
                  bid_strategy ? bid_strategy.replaceAll("_", " ") : null
                }}</span> -->
                <v-select
                  outlined
                  dense
                  :items="bid_strategies"
                  v-model="bid_strategy"
                  hide-details="auto"
                ></v-select>
                <!-- <span
                  class="text-body-2 ml-2 pointer text--disabled"
                  @click="edit_bid_strategy = !edit_bid_strategy"
                >
                  <v-icon small disabled>mdi-pencil-outline</v-icon> Edit
                </span> -->
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </general-card>
</template>

<script>
import GeneralCard from "../../Layouts/Cards/GeneralCard.vue";

export default {
  data: () => ({
    billing_events: [
      "app_installs",
      "impressions",
      "link_clicks",
      "listing_interaction",
      "none",
      "offer_claims",
      "page_likes",
      "post_engagement",
      "purchase",
      "thruplay",
    ],
    show_more: false,
    billing_event: "impressions",
    edit_billing_event: false,
    bid_strategies: ["cost_cap", "lowest_cost_without_cap", "lowest_cost_with_bid_cap"],
    bid_strategy: "lowest_cost_without_cap",
    edit_bid_strategy: false,
  }),
  props: {
    facebook_single_adset: {
      type: Object,
    },
  },
  components: {
    GeneralCard,
  },
  methods: {
    showMore() {
      this.show_more = !this.show_more;
    },
  },
  watch: {
    billing_event() {
      this.$emit("setBillingEvent", this.billing_event.toUpperCase());
    },
    bid_strategy() {
      this.bid_strategy
        ? this.$emit("setBidStrategy", this.bid_strategy.toUpperCase())
        : null;
    },
    facebook_single_adset() {
      this.facebook_single_adset ? (this.show_more = true) : null;
      this.billing_event = this.facebook_single_adset
        ? this.facebook_single_adset.billing_event
        : "impressions";
      this.bid_strategy = this.facebook_single_adset
        ? this.facebook_single_adset.bid_strategy
        : "lowest_cost_without_cap";
    },
  },
  mounted() {
    if (this.$route.name == "create-facebook-campaign") {
      this.$emit("setBillingAndBid", {
        billing_event: this.billing_event,
        bid_strategy: this.bid_strategy,
      });
    }
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
