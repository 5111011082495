<template>
  <div>
    <v-row>
      <v-col>
        <h6>Asset customisation</h6>
        <p class="text-caption text--disabled">
          {{ checked.length }} placements that support asset customisation
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels
          accordion
          tile
          v-for="(placement, i) in placements"
          :key="i"
        >
          <v-expansion-panel>
            <v-expansion-panel-header @click.native="expansionPanel()">
              <template v-slot:actions>
                <v-icon class="icon">mdi-menu-down</v-icon>
              </template>
              <span class="header d-flex align-center">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><h6>{{ placement.title }}</h6></v-list-item-title
                    >
                    <v-list-item-subtitle class="text-caption">{{
                      placement.subtitle
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-spacer></v-spacer>
                <v-checkbox v-model="checkbox" @click.native="check($event)">
                </v-checkbox>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              v-for="(item, i) in placement.options"
              :key="i"
            >
              <div class="d-flex justify-space-between">
                <span class="pl-10 text-caption">{{ item }}</span>
                <v-checkbox
                  v-model="checked"
                  :value="item"
                  @click.native="check($event)"
                ></v-checkbox>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    checkbox: true,
    checked: [],
    radioGroup: 1,
    switch1: true,
    placements: [
      {
        title: "Feeds",
        subtitle: "Get high visibility for your business with ads in feeds",
        options: [
          "Facebook News Feed",
          "Instagram feed",
          "Instagram Explore Page",
          "Facebook Marketplace",
          "Facebook video feeds",
          "Instagram Shop Page",
        ],
      },
      {
        title: "Stories and Reels",
        subtitle:
          "Tell a rich, visual story with immersive, full-screen vertical ads",
        options: [
          "Instagram Stories",
          "Facebook Stories",
          "Messenger Stories",
          "Instagram Reels",
        ],
      },
      {
        title: "In-stream",
        subtitle:
          "Quickly capture people's attention while they're watching videos",
        options: ["Facebook in-stream videos"],
      },
      {
        title: "Reels overlay",
        subtitle:
          "Reach people with sticker or banner ads as they watch short-form content",
        options: ["Facebook overlay ads in Reels"],
      },
      {
        title: "Search",
        subtitle:
          "Get visibility for your business as people search on Facebook",
        options: ["Facebook search results"],
      },
      {
        title: "Messages",
        subtitle:
          "Send offers or updates to people who are already connected to your business",
        options: ["Messenger sponsored messages"],
      },
      {
        title: "In-article",
        subtitle: "Engage with people reading content from publishers",
        options: ["Facebook Instant Articles"],
      },
      {
        title: "Apps and sites",
        subtitle: "Expand your reach with ads in external apps and websites",
        options: [
          "Audience Network native, banner and interstitial",
          "Audience Network rewarded videos",
          "Audience Network in-stream videos",
        ],
      },
    ],
    facebook_positions: [],
    instagram_positions: [],
    audience_network_positions: [],
    messenger_positions: [],
  }),
  methods: {
    check: function(e) {
      e.cancelBubble = true;
      console.log("checkbox checked");
    },
    expansionPanel: function() {
      console.log("expansion panel");
    },
  },

  props: {
    facebook_single_adset: {
      type: Object,
    },
  },

  watch: {
    checked() {
      if (this.checked !== []) {
        let feed = this.checked.indexOf("Facebook News Feed");
        if (feed !== -1) {
          this.facebook_positions.push((this.checked[feed] = "feed"));
        }
        let video_feeds = this.checked.indexOf("Facebook video feeds");
        if (video_feeds !== -1) {
          this.facebook_positions.push(
            (this.checked[video_feeds] = "video_feeds")
          );
        }
        let marketplace = this.checked.indexOf("Facebook Marketplace");
        if (marketplace !== -1) {
          this.facebook_positions.push(
            (this.checked[marketplace] = "marketplace")
          );
        }
        let facebook_story = this.checked.indexOf("Facebook Stories");
        if (facebook_story !== -1) {
          this.facebook_positions.push(
            (this.checked[facebook_story] = "story")
          );
        }
        let instream_video = this.checked.indexOf("Facebook in-stream videos");
        if (instream_video !== -1) {
          this.facebook_positions.push(
            (this.checked[instream_video] = "instream_video")
          );
        }
        let instant_articles = this.checked.indexOf(
          "Facebook Instant Articles"
        );
        if (instant_articles !== -1) {
          this.facebook_positions.push(
            (this.checked[instant_articles] = "instant_article")
          );
        }
        let facebook_search = this.checked.indexOf("Facebook search results");
        if (facebook_search !== -1) {
          this.facebook_positions.push(
            (this.checked[facebook_search] = "search")
          );
        }

        //Instagram

        let explore = this.checked.indexOf("Instagram Explore Page");
        if (explore !== -1) {
          this.instagram_positions.push((this.checked[explore] = "explore"));
        }
        let stories = this.checked.indexOf("Instagram Stories");
        if (stories !== -1) {
          this.instagram_positions.push((this.checked[stories] = "story"));
        }
        let reels = this.checked.indexOf("Instagram Reels");
        if (reels !== -1) {
          this.instagram_positions.push((this.checked[reels] = "reels"));
        }
        // let stream = this.checked.indexOf("Instagram in-stream videos");
        // if (stream !== -1) {
        //   this.instagram_positions.push(
        //     (this.checked[stream] = "stream")
        //   );
        // }
        let shop = this.checked.indexOf("Instagram Shop Page");
        if (shop !== -1) {
          this.instagram_positions.push((this.checked[shop] = "shop"));
        }
        let instagram_feed = this.checked.indexOf("Instagram feed");
        if (instagram_feed !== -1) {
          this.instagram_positions.push(
            (this.checked[instagram_feed] = "stream")
          );
        }

        //Audience Network Positions
        let classic = this.checked.indexOf(
          "Audience Network native, banner and interstitial"
        );
        if (classic !== -1) {
          this.audience_network_positions.push(
            (this.checked[classic] = "classic")
          );
        }
        let rewarded_video = this.checked.indexOf(
          "Audience Network rewarded videos"
        );
        if (rewarded_video !== -1) {
          this.audience_network_positions.push(
            (this.checked[rewarded_video] = "rewarded_video")
          );
        }
        let network_instream = this.checked.indexOf(
          "Audience Network in-stream videos"
        );
        if (network_instream !== -1) {
          this.audience_network_positions.push(
            (this.checked[network_instream] = "rewarded_video")
          );
        }

        //Messenger Placement
        let messenger_stories = this.checked.indexOf("Messenger Stories");
        if (messenger_stories !== -1) {
          this.messenger_positions.push(
            (this.checked[messenger_stories] = "story")
          );
        }

        let messenger_messages = this.checked.indexOf(
          "Messenger sponsored messages"
        );
        if (messenger_messages !== -1) {
          this.messenger_positions.push(
            (this.checked[messenger_messages] = "sponsored_messages")
          );
        }

        this.$emit("emitFacebookPositions", this.facebook_positions);
        this.$emit("emitInstagramPositions", this.instagram_positions);
        this.$emit(
          "emitAudienceNetworkPositions",
          this.audience_network_positions
        );
        this.$emit("emitMessengerPositions", this.messenger_positions);
      }
    },
  },
};
</script>

<style scoped>
.icon {
  order: 0;
}

.header {
  order: 1;
}

.v-expansion-panel::before {
  box-shadow: none;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  border: 1px rgba(0, 0, 0, 0.1) solid;
}
</style>
