<template>
  <div class="section-height">
    <v-row class="py-2 d-flex align-center">
      <v-col>
        <h4 class="text--disabled text-uppercase">Creative Options</h4>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="py-2 d-flex align-center">
      <v-col cols="4">
        <h6>Click Through URL</h6>
      </v-col>
      <v-col>
        <v-text-field
          v-model="clickThroughURL"
          single-line
          hide-details
          outlined
          rounded
          dense
          height="1"
          color="#3371E2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="py-2">
      <v-col cols="4">
        <h6>Cascade Click URL</h6>
      </v-col>
      <v-col class="mb-4">
        <v-radio-group class="mb-4" v-model="cascadeClickURL" dense>
          <v-radio label="Yes" color="primary" :value="true"></v-radio>
          <v-radio
            label="Use individual landing pages"
            color="primary"
            :value="false"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="pt-2 pb-1 d-flex align-center">
      <v-col cols="4">
        <h6>Ad Banner Branding</h6>
      </v-col>
      <v-col>
        <v-radio-group v-model="banner" dense row>
          <v-radio label="Yes" :value="true"></v-radio>
          <v-radio label="No" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row v-if="banner" class="py-2 d-flex align-center">
      <v-col cols="4">
        <h6 class="pt-2">Banner Branding Type</h6>
      </v-col>
      <v-col>
        <v-radio-group v-model="brandingType" dense>
          <v-radio value="orange">
            <template v-slot:label>
              <div>
                <v-img
                  :src="require('../../assets/pal-orange.svg')"
                  max-width="20"
                ></v-img>
              </div>
            </template>
          </v-radio>
          <v-radio value="blue">
            <template v-slot:label>
              <div>
                <v-img
                  :src="require('../../assets/pal-blue.svg')"
                  max-width="20"
                ></v-img>
              </div>
            </template>
          </v-radio>
          <v-radio value="mediapal">
            <template v-slot:label>
              <div>
                <v-img
                  :src="require('../../assets/mediapal-blue.svg')"
                  max-width="50"
                ></v-img>
              </div>
            </template>
          </v-radio>
          <v-radio value="adchoices">
            <template v-slot:label>
              <div>
                <v-img
                  :src="require('../../assets/adchoices.svg')"
                  max-width="20"
                ></v-img>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    campaign: Object,
    default: () => {},
  },
  data: () => ({
    periods: ["Daily", "Weekly", "General"],
    clickThroughURL: "",
    cascadeClickURL: true,
    banner: true,
    brandingType: "orange",
    radios: "",
  }),
  watch: {
    clickThroughURL() {
      this.clickthroughurl();
    },
    cascadeClickURL() {
      this.cascadeclickurl();
    },
    brandingType() {
      this.brandingtype();
    },
    banner() {
      this.bannerbranding();
    },
    campaign() {
      if (this.$route.name == "edit-dsp-campaign" && this.campaign) {
        this.clickThroughURL = this.campaign.ad_domain;
        this.cascadeClickURL =
          this.campaign.campaign_attribute.cascade_click_url == 1
            ? true
            : false;
        this.banner = this.campaign.campaign_attribute.add_banner_branding;
        this.brandingType = this.campaign.campaign_attribute.branding_type
          ? this.campaign.campaign_attribute.branding_type.toString()
          : this.campaign.campaign_attribute.branding_type;
      }
    },
  },
  methods: {
    cascadeclickurl() {
      this.$emit("emitCascadeClickURL", this.cascadeClickURL);
    },
    clickthroughurl() {
      this.$emit("emitClickThroughURL", this.clickThroughURL);
    },
    bannerbranding() {
      this.$emit("emitBannerBranding", this.banner);
    },
    brandingtype() {
      this.$emit("emitBrandingType", this.brandingType);
    },
  },
  mounted() {
    this.cascadeclickurl();
    this.clickthroughurl();
    this.bannerbranding();
    this.brandingtype();
  },
};
</script>

<style>
/* .section-height {
  min-height: 70vh;
}

.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.v-subheader {
  padding: 0;
} */
</style>