<template>
  <v-row>
    <v-col>
      <v-card-title class="d-flex align-center justify-space-between py-2">
        <h5>Dynamic creative</h5>
        <v-switch
          v-model="dynamicCreative"
          :label="`${dynamicCreative ? 'On' : 'Off'}`"
          hide-details
          class="mt-0"
        ></v-switch>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <p class="text-caption">
          Provide creative elements, such as images and headlines, and we'll automatically
          generate combinations optimised for your audience. Variations may include
          different formats or templates based on one or more elements.
        </p>
      </v-card-text>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    dynamicCreative: false,
  }),
  watch: {
    dynamicCreative() {
      this.$emit("emitDynamicCreative", this.dynamicCreative);
    },
  },
  beforeDestroy() {
    this.dynamicCreative = false;
  },
};
</script>
