<template v-slot:extension>
  <v-row>
    <v-tab href="#general">
      <span class="text-capitalize tab-text">{{
        this.$route.name == "create-facebook-campaign" ||
        this.$route.name == "new-campaign"
          ? "Create New Campaign"
          : "Edit Existing Campaign"
      }}</span>
    </v-tab>
    <v-tab href="#adset">
      <span class="text-capitalize tab-text">{{
        this.$route.name == "create-facebook-campaign" ||
        this.$route.name == "new-campaign"
          ? "Create New Adset"
          : "Edit Existing Adset"
      }}</span>
    </v-tab>
    <v-tab href="#visuals">
      <span class="text-capitalize tab-text">{{
        this.$route.name == "create-facebook-campaign" ||
        this.$route.name == "new-campaign"
          ? "Create New Ad"
          : "Edit Existing Ad"
      }}</span>
    </v-tab>
  </v-row>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
    },
  },
  computed: {
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
  },
};
</script>
