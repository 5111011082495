<template>
  <v-row class="py-5">
    <v-col cols="12">
      <h6>Platforms</h6>
    </v-col>
    <v-col v-for="platform in platforms" :key="platform" cols="4">
      <v-checkbox
        :label="platform"
        :value="platform"
        v-model="selected_platforms"
        dense
      >
      </v-checkbox
    ></v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    platforms: ["Facebook", "Instagram", "Audience Network", "Messenger"],
    selected_platforms:[],
  }),
  watch:{
    selected_platforms(){
      let i;
      let publisher_platforms = [];
      for (i = 0; i < this.selected_platforms.length; i++) {
        publisher_platforms.push(this.selected_platforms[i].toLowerCase());
      }
      let index = publisher_platforms.indexOf("audience network");
      if (index !== -1) {
        publisher_platforms[index] = "audience_network";
      }
      this.$emit("emitPublisherPlatforms", publisher_platforms)
    }
  }
};
</script>